import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled, lighten, darken } from "@mui/system";

export default function Grouped() {
  const options = superSearchResults.map((option) => {
    return {
      ...option,
    };
  });

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    backgroundColor:
      theme.palette.mode === "light"
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  return (
    <Autocomplete
      id="grouped-demo"
      options={options.sort((a, b) => -b.type.localeCompare(a.type))}
      groupBy={(option) => option.type}
      getOptionLabel={(option) => option.displayName}
      sx={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Search for Orders, Stores or Partners" />
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
}

const superSearchResults = [
  {
    id: 3231955,
    type: "Stores",
    displayName: "russel - fort lee, New Jersey",
  },
  {
    id: 3120568,
    type: "Stores",
    displayName: "Russel - , ",
  },
  {
    id: 3122039,
    type: "Stores",
    displayName: "russel - poughkeepsie, ny",
  },
  {
    id: 197513,
    type: "Stores",
    displayName: "Russel Bede School - San Mateo, California",
  },
  {
    id: 2644464,
    type: "Stores",
    displayName: "Russell - Russell, New York",
  },
  {
    id: 1736,
    type: "Partners",
    displayName: "Russell -shell account for licensing",
  },
  {
    id: 1731,
    type: "Partners",
    displayName: "Russell Athletics-shell account for links",
  },
  {
    id: 5165,
    type: "Products",
    displayName: "R697HBM1",
  },
  {
    id: 4539,
    type: "Products",
    displayName: "697HBM1",
  },
  {
    id: 5149,
    type: "Products",
    displayName: "697HBM",
  },
  {
    id: 4832,
    type: "Products",
    displayName: "S82JZMK",
  },
  {
    id: 5342,
    type: "Products",
    displayName: "64STTM0",
  },
];
