import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useCallback, useContext } from "react";
import AddressEdit from "../Components/Common/AddressEdit";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
import ColorPicker from "../Components/Common/ColorPicker";
import StoreTypesDropdown from "../Components/Common/StoreTypesDropDown";
import { AuthContext } from "../store/auth-context";

export default function CreateStore() {
  const [storeName, setstoreName] = React.useState("");
  const [shortAbbr, setshortAbbr] = React.useState("");
  const [longAbbr, setlongAbbr] = React.useState("");
  const [mascot, setMascot] = React.useState("");
  const [storeType, setStoreType] = React.useState("");

  const handleStoreChange = function (e) {
    setstoreName(e.target.value);

    // create suggested abbreviations
    var matches = storeName.match(/\b(\w)/g);
    var acronym;
    if (matches) {
      acronym = matches.join("").toUpperCase();
      setlongAbbr(acronym.slice(0, 3));
      setshortAbbr(acronym.slice(0, 2));
    } else {
      setshortAbbr("");
      setlongAbbr("");
    }
  };

  const authCtx = useContext(AuthContext);
  const crumbs = [
    {
      title: "Stores",
      route: "/stores",
    },
    {
      title: "Create",
    },
  ];
  const createStore = useCallback(async () => {
    const response = await authCtx.request(
      "PUT",
      `${process.env.REACT_APP_Prep_Api_Host}store/`,
      {
        configuration: {
          name: storeName,
          nameForPrint: storeName,
          shortAbbreviation: shortAbbr,
          longAbbreviation: setlongAbbr,
          color1: "orange",
          color2: "black",
          color3: "white",
          mascot: mascot,
          ladyMascot: `Lady ${storeName}`,
          mascotAbbreviation: mascot ? mascot.charAt(0) : "",
          mascotImage: mascot,
          teamName: "",
          teamName1: "Albert City",
          teamName2: "Truesdale School",
          teamNameAbbreviation: "",
          teamNameShortAbbreviation: "",
          teamNameForPrint: "",
          leagueName: "",
          leagueAbbreviation: "",
          organization: "",
          level: "",
          sport: "",
          jerseyName: mascot,
          jerseyNameAlt: null,
          stadium: null,
          yearFrom: "",
          yearTo: "",
        },
        settings: {
          enabled: true,
          showOnlyDesigns: null,
          showOnlyProductColors: "",
          showOnlyActivities: null,
          showOnlyProducts: null,
          showOnlyCategories: null,
          showOnlyDesignCategories: "",
          disableAccessToAllColors: false,
          hideFromSearch: false,
          doNotIndex: false,
          googleBasePublish: true,
          internalAccessOnly: false,
          version: 4,
          requestedRemoval: false,
        },
        storeAddress: {
          firstName: "",
          lastName: "",
          phoneNumbers: [],
          emailAddresses: [],
          faxNumber: "",
          url: "",
          address1: "",
          address2: "",
          city: "Albert City",
          state: "Iowa",
          zip: "50510",
          country: "us",
          county: "",
        },
        storeContact: {
          firstName: "",
          lastName: "",
          phoneNumbers: [],
          emailAddresses: [],
          faxNumber: "",
          url: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          county: "",
        },
        type: storeType,
        modifiedBy: 164292282,
      }
    );
    console.log(response);
  }, [authCtx]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs crumbs={crumbs}>
            <Typography color="text.primary">Breadcrumbs</Typography>
          </BreadCrumbs>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  pt: 0,
                  pb: 3,
                }}
                variant="h4"
              >
                Create Store
              </Typography>
              <Paper
                sx={{
                  p: 3,
                  mb: 3,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5" gutterBottom>
                      Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      placeholder="Store name"
                      fullWidth
                      required
                      id="storeName"
                      label="Store name"
                      onKeyDown={handleStoreChange}
                      onChange={handleStoreChange}
                      value={storeName}
                      helperText="Store name used when navigating the site"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      placeholder="Print Name"
                      fullWidth
                      required
                      id="printName"
                      label="Print Name"
                      helperText="Store name used on product embelishments"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      placeholder="Horned Frogs"
                      fullWidth
                      required
                      value={mascot}
                      id="mascot"
                      label="Mascot"
                      helperText="Store mascot. Used in many designs."
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <StoreTypesDropdown value={storeType} />
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}></Grid>
                </Grid>
              </Paper>
              <Paper
                sx={{
                  p: 3,
                  mb: 3,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <AddressEdit></AddressEdit>
              </Paper>
              <Paper
                sx={{
                  p: 3,
                  mb: 3,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5" gutterBottom>
                      Configuration
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} md={6} lg={6}>
                        <Typography sx={{ pt: 1.5 }} variant="subtitle1">
                          Color 1
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <ColorPicker></ColorPicker>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <Typography sx={{ pt: 1.5 }} variant="subtitle1">
                          Color 2
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <ColorPicker></ColorPicker>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <Typography sx={{ pt: 1.5 }} variant="subtitle1">
                          Color 3
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <ColorPicker></ColorPicker>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} md={1} lg={1}></Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} md={6} lg={6}>
                        <Typography sx={{ pt: 1.5 }} variant="subtitle1">
                          Short Abbreviation
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <TextField
                          fullWidth
                          required
                          id="outlined-required"
                          placeholder="JH"
                          value={shortAbbr}
                        />
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <Typography sx={{ pt: 1.5 }} variant="subtitle1">
                          Long Abbreviation
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <TextField
                          fullWidth
                          required
                          id="outlined-required"
                          placeholder="JHS"
                          value={longAbbr}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                sx={{
                  p: 3,
                  mb: 3,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5" gutterBottom>
                      Settings
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    md={9}
                    lg={9}
                    sx={{ p: 2, borderBottom: 1, borderColor: "grey.300" }}
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      <strong>Enabled</strong>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      The store is active on the site allowing customers to
                      purchase products from this store.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    lg={3}
                    sx={{
                      p: 2,
                      borderBottom: 1,
                      borderColor: "grey.300",
                      textAlign: "right",
                    }}
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <FormGroup>
                        <FormControlLabel control={<Switch defaultChecked />} />
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    md={9}
                    lg={9}
                    sx={{ p: 2, borderBottom: 1, borderColor: "grey.300" }}
                  >
                    <Typography variant="subtitle1" gutterBottom>
                      <strong>Show in Site Search</strong>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Allow the store to be found using the site's own search
                      functionality.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    md={3}
                    lg={3}
                    sx={{ p: 2, borderBottom: 1, borderColor: "grey.300" }}
                  >
                    <Box display="flex" justifyContent="flex-end">
                      <FormGroup>
                        <FormControlLabel control={<Switch defaultChecked />} />
                      </FormGroup>
                    </Box>
                  </Grid>
                  <Grid item xs={9} md={9} lg={9} sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      <strong>Search Engine Crawling</strong>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Allow search engines to crawl and index this store.
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3} sx={{ p: 2 }}>
                    <Box display="flex" justifyContent="flex-end">
                      <FormGroup>
                        <FormControlLabel control={<Switch />} />
                      </FormGroup>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
              <Grid item xs={12} md={12} lg={12}>
                <Stack direction="row" spacing={2}>
                  <Button
                    onClick={() => {
                      createStore();
                    }}
                    size="large"
                    variant="contained"
                  >
                    Create Store
                  </Button>
                  <Button size="large" variant="text" href="#/stores">
                    Cancel
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
