import Chip from "@mui/material/Chip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import { yellow } from "@mui/material/colors";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";

export default function StatusChip(status) {
  let statusDetail = {
    text: "",
    color: "",
  };

  switch (status.status) {
    case -1:
      statusDetail = { text: "Declined", color: "primary" };
      break;
    case -4:
      statusDetail = { text: "Error", color: "primary" };
      break;
    case -5:
      statusDetail = { text: "Failed", color: "primary" };
      break;
    case -6:
      statusDetail = { text: "Void", color: "error", icon: <BlockIcon /> };
      break;
    case -7:
      statusDetail = { text: "Held for Review", color: "primary" };
      break;
    case -8:
      statusDetail = {
        text: "Out of Stock",
        sx: { bgcolor: yellow[600] },
        icon: <ErrorOutlineIcon />,
      };
      break;
    case -10:
      statusDetail = { text: "Void", color: "error", icon: <BlockIcon /> };
      break;
    case -11:
      statusDetail = { text: "Error During Void", color: "primary" };
      break;
    case 0:
      statusDetail = { text: "Pending", color: "primary" };
      break;
    case 1:
      statusDetail = { text: "Authorized", color: "primary" };
      break;
    case 2:
      statusDetail = {
        text: "Captured",
        variant: "outlined",
        color: "primary",
        icon: <LocalMallOutlinedIcon />,
      };
      break;
    case 14:
      statusDetail = {
        text: "Partially Shipped",
        color: "success",
        variant: "outlined",
        icon: <LocalShippingTwoToneIcon />,
      };
      break;
    case 15:
      statusDetail = {
        text: "Shipped",
        color: "success",
        icon: <LocalShippingTwoToneIcon />,
      };
      break;
    case 16:
      statusDetail = {
        text: "Complete",
        color: "success",
        icon: <CheckCircleIcon />,
      };
      break;
    default:
      statusDetail = { text: "Unknown", color: "primary" };
      break;
  }

  return (
    <Chip
      sx={statusDetail.sx ? statusDetail.sx : ""}
      icon={statusDetail.icon}
      label={statusDetail.text}
      size="small"
      color={statusDetail.color}
      variant={statusDetail.variant ? statusDetail.variant : ""}
    ></Chip>
  );
}
