import { useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { MessageContext } from "../../store/message-context";

const SnackbarAlert = () => {
  const { open, messageSeverity, alertMessage } = useContext(MessageContext);
  const messageCtx = useContext(MessageContext);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    messageCtx.hideMessage();
  };

  if (messageSeverity && alertMessage) {
    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseAlert} severity={messageSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    );
  } else {
    return <></>;
  }
};

export default SnackbarAlert;
