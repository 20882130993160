import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../store/auth-context";
import Table from "../../Components/Common/Table";
import { EmailColumns as columns } from "../Order/TableHeaders/Columns";

export default function OrderHistory() {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const [email, setEmail] = useState([]);
  const [showEmail, setShowEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function getEmails() {
      setIsLoading(true);

      try {
        const emailData = await authCtx.request(
          "GET",
          `${process.env.REACT_APP_Prep_Api_Host}order/${params.id}/email`,
        );

        if (emailData && emailData.data) {
          setEmail(emailData.data);
          setIsLoading(false);
          setShowEmail(true);
        }
      } catch (error) {
        setOpen(true);
        setError(error);
      }
    }
    getEmails();
  }, [authCtx, params.id]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Paper
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={12}>
          <Typography variant="h5">Order Emails</Typography>
        </Grid>
        {showEmail && (
          <Grid xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Table
                data={email}
                rowCount={email.length}
                isLoading={isLoading}
                columns={columns}
                disablePaging={true}
                pagination={[]}
                pageSizeOptions={[]}
                density="standard"
                columnVisibilityModel={{
                  id: false,
                }}
              />
            </Paper>
          </Grid>
        )}
        {error && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
              {error.message}
            </Alert>
          </Snackbar>
        )}
      </Grid>
    </Paper>
  );
}
