import React, { useState } from "react";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { FormControl, FormGroup } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Label from "../../Common/Label";
import InputAdornment from "@mui/material/InputAdornment";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LockIcon from "@mui/icons-material/Lock";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Amount from "./Amount";

import TagIcon from "@mui/icons-material/Tag";
import { ADD_PAYMENT_REASONS, PAYMENT_TYPES } from "../../../shared/constants";

const styles = {
  formGroup: {
    padding: 10,
  },
};
export default function AddPaymentForm({ onClose }) {
  const [reason, setReason] = useState("");
  const [paymentType, setPaymentType] = useState("");

  const submitNewPayment = () => {};

  const handleChangeReason = (event) => {
    setReason(event.target.value);
  };

  const handleChangePaymentType = (event) => {
    setPaymentType(event.target.value);
  };

  const handleSubmitCreditCard = () => {
    onClose();
  };

  const handleSubmitPurchaseOrder = () => {
    onClose();
  };

  const CreditCardPaymentType = () => {
    return (
      <div>
        <FormGroup style={styles.formGroup}>
          <FormControl fullWidth>
            <TextField
              label="Name on Card"
              id="standard-start-adornment"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </FormControl>
        </FormGroup>

        <FormGroup style={styles.formGroup}>
          <FormControl fullWidth>
            <TextField
              label="Credit Card"
              id="standard-start-adornment"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </FormControl>
        </FormGroup>

        <FormGroup style={styles.formGroup}>
          <FormControl fullWidth>
            <TextField
              placeholder="MM/YY"
              label="Expiration"
              id="standard-start-adornment"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarMonthIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </FormControl>
        </FormGroup>

        <FormGroup style={styles.formGroup}>
          <FormControl fullWidth>
            <TextField
              label="CVV"
              id="standard-start-adornment"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </FormControl>
        </FormGroup>

        <FormGroup style={styles.formGroup}>
          <FormControl fullWidth>
            <TextField
              label="Billing Zip"
              id="standard-start-adornment"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </FormControl>
        </FormGroup>

        <Amount />
        <FormControl>
          {" "}
          <Button onClick={handleSubmitCreditCard}>Submit</Button>
        </FormControl>
      </div>
    );
  };

  const PurchaseOrderPaymentType = () => {
    return (
      <div>
        <FormGroup style={styles.formGroup}>
          <FormControl fullWidth>
            <TextField
              label="PO"
              id="standard-start-adornment"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TagIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </FormControl>
        </FormGroup>
        <Amount />
        <FormControl>
          {" "}
          <Button onClick={handleSubmitPurchaseOrder}>Submit</Button>
        </FormControl>
      </div>
    );
  };

  return (
    <div>
      <Label variant="h6" label="Add New Payment" />
      <Formik initialValues={{}} onSubmit={submitNewPayment}>
        <Form>
          <FormGroup style={styles.formGroup}>
            <FormControl fullWidth>
              <InputLabel id="reasons-select">Reason</InputLabel>
              <Select
                labelId="reasons-select"
                id="reasons-select"
                onChange={handleChangeReason}
                value={reason}
                label="Reason"
              >
                {ADD_PAYMENT_REASONS.map((reason, index) => (
                  <MenuItem value={reason} key={index}>
                    {reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>

          <FormGroup style={styles.formGroup}>
            <FormControl fullWidth>
              <InputLabel id="payment-type-select">Payment Type</InputLabel>
              <Select
                labelId="payment-type-select"
                id="payment-type-select"
                onChange={handleChangePaymentType}
                value={paymentType}
                label="Payment Type"
              >
                {PAYMENT_TYPES.map((type, index) => (
                  <MenuItem value={type} key={index}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
          {paymentType === "Credit Card" && <CreditCardPaymentType />}
          {paymentType === "Purchase Order" && <PurchaseOrderPaymentType />}
        </Form>
      </Formik>
    </div>
  );
}
