import React from "react";
import TextField from "@mui/material/TextField";
import { FormControl, FormGroup } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InputAdornment from "@mui/material/InputAdornment";
const styles = {
  formGroup: {
    padding: 10,
  },
};
export default function Amount() {
  return (
    <div>
      <FormGroup style={styles.formGroup}>
        <FormControl fullWidth>
          <TextField
            label="Amount"
            id="standard-start-adornment"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </FormControl>
      </FormGroup>
    </div>
  );
}
