import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import CountriesDropdown from "../Common/CountriesDropdown";
import StatesDropdown from "../Common/StatesDropdown";

export default function Address({ address }) {
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [city, setCity] = React.useState("");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h5" gutterBottom>
          Address
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          placeholder="Address 1"
          fullWidth
          required
          id="outlined-required"
          label="Address 1"
          onChange={(event) => {
            setAddress1(event.target.value);
          }}
          value={address.address1}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextField
          placeholder="Unit #, P"
          fullWidth
          id="outlined-required"
          label="Address 2"
          onChange={(event) => {
            setAddress2(event.target.value);
          }}
          value={address.address2}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          placeholder="City"
          fullWidth
          required
          id="outlined-required"
          label="City"
          onChange={(event) => {
            setCity(event.target.value);
          }}
          value={address.city}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <StatesDropdown value={address.state}></StatesDropdown>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TextField
          placeholder="Postal Code"
          fullWidth
          required
          id="outlined-required"
          label="Postal Code"
          onChange={(event) => {
            setCity(event.target.value);
          }}
          value={address.zip}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <CountriesDropdown value={address.country}></CountriesDropdown>
      </Grid>
    </Grid>
  );
}

Address.propTypes = {
  address: PropTypes.object,
};
