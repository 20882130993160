import {
  FormControl,
  FormGroup,
  IconButton,
  Tooltip,
  Button,
  Stack,
} from "@mui/material";
import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import Label from "../../Common/Label";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { REPLACEMENT_REASONS } from "../../../shared/constants";
import { OrderItemContext } from "../../../store/order-item-context";
import { AuthContext } from "../../../store/auth-context";
import { Grid } from "@mui/material";
import { FormatProductName } from "../../../shared/utils";
import TextField from "@mui/material/TextField";
import { forIn, groupBy } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
const styles = {
  formGroup: {
    padding: 10,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: 800,
  },
  top: {
    minHeight: 100,
  },
  middle: {
    flexGrow: 1,
    overflow: "scroll",
    marginTop: 10,
  },
  bottom: {
    marginTop: 10,
    padding: 10,
    minHeight: 50,
  },
};

export default function ReplacementForm({ onClose }) {
  const [reason, setReason] = useState("");
  const [productOptions, setProductOptions] = useState([]);
  const [replacementProducts, setReplacementProducts] = useState([]);
  const [placeOrderEnabled, setPlaceOrderEnabled] = useState(false);
  const { Items } = useContext(OrderItemContext);
  const authCtx = useContext(AuthContext);

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  useEffect(() => {
    async function getProductOptions() {
      Items.forEach(async (item) => {
        const results = await authCtx.request(
          "GET",
          `${process.env.REACT_APP_Prep_Api_Host}product/options/${item.productId}`,
        );

        if (results && results.data) {
          setProductOptions((prevOptions) => [...prevOptions, results.data]);
        }
      });
    }

    getProductOptions();
  }, [Items, authCtx]);

  useEffect(() => {
    if (replacementProducts.length > 0 && reason) {
      setPlaceOrderEnabled(true);
    } else {
      setPlaceOrderEnabled(false);
    }
  }, [reason, replacementProducts.length]);

  const Product = ({
    index,
    orderId,
    orderLineItemId,
    productOptions,
    purchasedItemDetails,
    addToOrder,
  }) => {
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedSize, setSelectedSize] = useState("");
    const [selectedQuantity, setSelectedQuantity] = useState("");

    const [availableColors, setAvailableColors] = useState([]);
    const [availableSizes, setAvailableSizes] = useState([]);
    const { size, color } = purchasedItemDetails;
    const { name } = purchasedItemDetails.product;

    const [addToOrderEnabled, setAddToOrderEnabled] = useState(false);

    useLayoutEffect(() => {
      forIn(groupBy(productOptions, "color"), (productOption, color) => {
        if (color === selectedColor) {
          const sizes = productOption.map((option) => option.size);
          setAvailableSizes(sizes);
        }
        if (!availableColors.includes(color)) {
          setAvailableColors((prevColor) => [...prevColor, color]);
        }
      });
    }, [availableColors, productOptions, selectedColor]);

    useEffect(() => {
      if (
        selectedQuantity &&
        parseInt(selectedQuantity) > 0 &&
        selectedColor &&
        selectedSize
      ) {
        setAddToOrderEnabled(true);
      } else {
        setAddToOrderEnabled(false);
      }
    }, [selectedColor, selectedQuantity, selectedSize]);

    const handleColorChange = (event, index) => {
      event.preventDefault();
      const selectedColor = event.target.value;
      setSelectedColor(selectedColor);
      setSelectedSize("");
    };

    const handleSizeChange = (event, index) => {
      event.preventDefault();
      const selectedSize = event.target.value;
      setSelectedSize(selectedSize);
    };
    const handleQuantityChange = (event, index) => {
      event.preventDefault();
      const quantity = event.target.value;
      setSelectedQuantity(quantity);
    };

    const addItemToOrder = () => {
      if (selectedColor && selectedQuantity && selectedSize) {
        addToOrder({
          name,
          color: selectedColor,
          quantity: parseInt(selectedQuantity),
          size: selectedSize,
          orderId,
          orderLineItemId,
        });
      }
    };

    const formatProductColor = (color) => color.replace("_", " ").toUpperCase();
    return (
      <Grid mb={2} container spacing={2}>
        <Grid item xs>
          {FormatProductName(name)}
        </Grid>
        <Grid item xs>
          {size}
        </Grid>
        <Grid item xs>
          {formatProductColor(color)}
        </Grid>
        <Grid item xs>
          <FormControl>
            <TextField
              id="outlined-basic"
              label="Quantity"
              value={selectedQuantity}
              onChange={(e) => handleQuantityChange(e, index)}
            />
          </FormControl>
        </Grid>
        <Grid item xs>
          <FormControl>
            <InputLabel id="color-select">Color</InputLabel>
            <Select
              labelId="color-select"
              id="color-select"
              autoWidth
              onChange={(e) => handleColorChange(e, index)}
              value={selectedColor}
              label="Color"
              sx={{ minWidth: 200 }}
            >
              {availableColors.map((color, index) => (
                <MenuItem value={color} key={index}>
                  {color}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs>
          <FormControl>
            <InputLabel id="size-select">Size</InputLabel>
            <Select
              disabled={!selectedColor}
              labelId="color-select"
              id="color-select"
              autoWidth
              onChange={(e) => handleSizeChange(e, index)}
              value={selectedSize}
              label="Color"
              sx={{ minWidth: 100 }}
            >
              {availableSizes.map((size, index) => (
                <MenuItem value={size} key={index}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs>
          <Button disabled={!addToOrderEnabled} onClick={addItemToOrder}>
            Add To Order
          </Button>
        </Grid>
      </Grid>
    );
  };

  const handleSubmitReplacementOrder = (e) => {
    e.preventDefault();
    replacementProducts.forEach(async (product) => {
      try {
        const data = {
          orderId: product.orderId,
          orderLineItemId: product.orderLineItemId,
          color: product.color,
          size: product.size,
          quantity: product.quantity,
        };
        const results = await authCtx.request(
          "POST",
          `${process.env.REACT_APP_Prep_Api_Host}order/${product.orderId}/replace`,
          data,
        );
        if (results && results.data) {
          const { id } = results.data;
          const messageDetails = {
            message: `Replacement Order ${id} was successfully placed.`,
            severity: "success",
          };
          onClose(messageDetails);
        }
      } catch (error) {
        const messageDetails = {
          message: `Error placing Order. ${error}`,
          severity: "error",
        };
        onClose(messageDetails);
      }
    });
  };

  const addToOrder = (item) => {
    setReplacementProducts((prevProducts) => [...prevProducts, item]);
  };

  const deleteItem = (productToDelete) => {
    setReplacementProducts((prevProducts) => {
      return prevProducts.filter((product) => product !== productToDelete);
    });
  };

  return (
    <div style={styles.container}>
      <div style={styles.top}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Label variant="h6" label="Replacement Order" />
          </Grid>
          <Grid item xs={4}>
            <Tooltip title="Close Modal">
              <IconButton
                style={{ float: "right", padding: 5 }}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <FormGroup style={styles.formGroup}>
          <FormControl fullWidth>
            <InputLabel id="reasons-select">Reason</InputLabel>
            <Select
              labelId="reason-select"
              id="reason-select"
              onChange={handleReasonChange}
              value={reason}
              label="Reason"
            >
              {REPLACEMENT_REASONS.map((reason, index) => (
                <MenuItem value={reason} key={index}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </div>
      <div style={styles.middle}>
        <form>
          {Items.map((item, index) => (
            <FormGroup style={styles.formGroup}>
              <FormControl fullWidth>
                <Product
                  index={index}
                  key={item.id}
                  orderId={item.orderId}
                  orderLineItemId={item.id}
                  productOptions={productOptions[index]}
                  purchasedItemDetails={item}
                  addToOrder={addToOrder}
                />
              </FormControl>
            </FormGroup>
          ))}
        </form>
      </div>
      <div style={styles.bottom}>
        {replacementProducts.length > 0 && (
          <div>
            <Divider />
            <Label label="Items to be Replaced:" variant="h6" />
            {replacementProducts.map((product) => {
              return (
                <p>
                  {product.name.replace("<schoolname>", "")} Q:{" "}
                  {product.quantity} Color: {product.color} Size: {product.size}
                  <IconButton onClick={() => deleteItem(product)}>
                    <DeleteIcon />
                  </IconButton>
                </p>
              );
            })}
          </div>
        )}
        <Stack position={"bottom"} spacing={2} direction="row">
          <Button
            disabled={!placeOrderEnabled}
            variant="contained"
            onClick={handleSubmitReplacementOrder}
          >
            Place Replacement Order
          </Button>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </div>
    </div>
  );
}
