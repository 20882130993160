import { MuiColorInput } from "mui-color-input";
import React from "react";

const ColorPicker = ({ onChange }) => {
  const [color, setColor] = React.useState("#ffffff");

  const handleChange = (color, colors) => {
    setColor(colors.hex);
  };

  return <MuiColorInput value={color} format="hex" onChange={handleChange} />;
};

export default ColorPicker;
