import React, { useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { AuthContext } from "../store/auth-context";
import moment from "moment";
import Table from "../Components/Common/Table";
import SuperSearch from "../Components/Common/SuperSearch";
import LinearProgress from "@mui/material/LinearProgress";
import { DashboardOrderColumns as columns } from "../Components/Order/TableHeaders/Columns";
export default function Dashboard() {
  const authCtx = useContext(AuthContext);
  const [orders, setOrders] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (authCtx.isInRole(["Customer Service"])) {
      const timeOutId = setTimeout(() => getOrders(), 0);
      return () => clearTimeout(timeOutId);
      async function getOrders() {
        setIsLoading(true);

        const dateRange = [
          moment().subtract(60, "d").format(),
          moment().format(),
        ];

        // If there are no search params, there is no need to search
        const orderData = await authCtx.request(
          "GET",
          `${process.env.REACT_APP_Prep_Api_Host}Order?pageSize=10&pageNumber=0&dateRange=${dateRange}`,
        );
        if (orderData) {
          setOrders(orderData.data.results);
          setIsLoading(false);
        }
      }
    }
  }, [authCtx]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            sx={{
              pt: 0,
              pb: 3,
            }}
            variant="h4"
          >
            Dashboard
          </Typography>
          <Grid container spacing={3}>
            {authCtx.isInRole(["Customer Service"]) && (
              <Grid item xs={12} md={12} lg={7}>
                <Paper
                  sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={5} md={5} lg={5}>
                      <Typography
                        sx={{
                          pt: 0,
                          pb: 3,
                        }}
                        variant="h6"
                      >
                        Recent Orders
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      md={7}
                      lg={7}
                      sx={{
                        textAlign: "right",
                      }}
                    >
                      <Button
                        startIcon={<ShoppingCartIcon />}
                        sx={{
                          m: 0,
                          mr: 0,
                          mb: 3,
                          ml: 19,
                        }}
                        variant="outlined"
                        component={RouterLink}
                        to="/orders"
                        size="small"
                      >
                        More Orders
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      {isLoading && <LinearProgress />}
                      {isLoading && (
                        <Box>
                          <Skeleton height={40} animation="wave" />
                          <Skeleton height={40} animation="wave" />
                          <Skeleton height={40} animation="wave" />
                          <Skeleton height={40} animation="wave" />
                          <Skeleton height={40} animation="wave" />
                        </Box>
                      )}
                      {orders && (
                        <Table
                          isLoading={isLoading}
                          columns={columns}
                          data={orders}
                          rowCount={orders.length}
                          density="compact"
                          pagination={{
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          pageSizeOptions={[10]}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={5}>
              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    pt: 0,
                    pb: 3,
                  }}
                  variant="h6"
                >
                  Super Search Playground
                  <SuperSearch></SuperSearch>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
