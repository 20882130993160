import Breadcrumbs from "@mui/material/Breadcrumbs";
import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";
const BreadCrumbs = ({ crumbs }) => {
  const crumbMap = [{ title: "Admin", route: "/" }, ...crumbs];

  const crumbs2 = crumbMap.map((crumb) => {
    if (crumb.route) {
      return (
        <Link
          underline="hover"
          key="1"
          color="inherit"
          component={RouterLink}
          to={crumb.route}
        >
          {crumb.title}
        </Link>
      );
    } else {
      return (
        <Typography key="3" color="text.secondary">
          {crumb.title}
        </Typography>
      );
    }
  });

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mt: 2, mb: 4 }}>
      {crumbs2}
    </Breadcrumbs>
  );
};

BreadCrumbs.propTypes = {
  page: PropTypes.string,
};

export default BreadCrumbs;
