import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { REDIRECTOR_URL } from "../../../shared/constants";
export const StoreColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    renderCell: (params) => (
      <Link
        size="small"
        component={RouterLink}
        to={`/store/${params.row.id}`}
        style={{ marginLeft: 16 }}
      >
        {params.row.id}
      </Link>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    width: 80,
    valueGetter: (params) => {
      return params.row.settings.enabled;
    },
  },
  {
    field: "type",
    headerName: "Type",
    width: 100,
    valueGetter: (params) => {
      return params.row.type;
    },
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    valueGetter: (params) => {
      return params.row.configuration.name;
    },
  },
  {
    field: "country",
    headerName: "Country",
    width: 80,
    valueGetter: (params) => {
      return params.row.storeAddress.country
        ? params.row.storeAddress.country.toUpperCase()
        : "";
    },
  },
  {
    field: "city",
    headerName: "City",
    width: 160,
    valueGetter: (params) => {
      return params.row.storeAddress.city;
    },
  },
  {
    field: "state",
    headerName: "State",
    width: 160,
    valueGetter: (params) => {
      return params.row.storeAddress.state;
    },
  },
  {
    field: "mascot",
    headerName: "Mascot",
    width: 160,
    valueGetter: (params) => {
      return params.row.configuration.mascot;
    },
  },
  {
    field: "colors",
    headerName: "Colors",
    width: 120,
    renderCell: (params) => (
      <AvatarGroup max={4}>
        <Tooltip title={params.row.configuration.color1}>
          <Avatar
            sx={{
              bgcolor: params.row.configuration.color1,
              width: 24,
              height: 24,
            }}
            alt={params.row.configuration.color1.toUpperCase()}
            src="/static/images/avatar/2.jpg"
          />
        </Tooltip>
        <Tooltip title={params.row.configuration.color2.toUpperCase()}>
          <Avatar
            sx={{
              bgcolor: params.row.configuration.color2,
              width: 24,
              height: 24,
            }}
            alt={params.row.configuration.color2}
            src="/static/images/avatar/2.jpg"
          />
        </Tooltip>
        {params.row.configuration.color3 && (
          <Tooltip title={params.row.configuration.color3.toUpperCase()}>
            <Avatar
              sx={{
                bgcolor: params.row.configuration.color3,
                width: 24,
                height: 24,
              }}
              alt={params.row.configuration.color3}
              src="/static/images/avatar/2.jpg"
            />
          </Tooltip>
        )}
      </AvatarGroup>
    ),
  },
  {
    field: "tools",
    headerName: "Tools",
    width: 120,
    renderCell: (params) => (
      <>
        <Tooltip title="Visit Store">
          <Link
            target="_blank"
            href={`${REDIRECTOR_URL}?schoolid=${params.row.id}`}
          >
            <OpenInNewIcon />
          </Link>
        </Tooltip>
        <Tooltip title="Edit Color Rules">
          <Link target="_blank">
            <ColorLensIcon />
          </Link>
        </Tooltip>
      </>
    ),
  },
];
