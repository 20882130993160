export const jobStatuses = {
  ERROR_PRINTING: -8,
  ERROR_CUTTING: -4,
  READY_TO_CUT_ERROR_QUEING_FILES: -3,
  ERROR_GENERATING_FILES: -2,
  ERROR_CREATING: -1,
  CREATING_LIST: 0,
  READY_TO_GENERATE_FILES: 1,
  GENERATING_FILES: 2,
  READY_TO_CUT: 3,
  CUTTING: 4,
  CUT: 5,
  DELETE_AND_REGEN_FILES: 6,
  READY_TO_PRINT: 7,
  PRINTING: 8,
  READY_TO_MERGE: 9,
  MERGING: 10,
};
