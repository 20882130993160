import * as React from "react";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function Designs() {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs crumbs={[{ title: "Designs" }]}></BreadCrumbs>
          <Grid container spacing={3}>
            <Grid item xs={8} md={8} lg={8}>
              <Typography
                sx={{
                  pt: 0,
                  pb: 3,
                }}
                variant="h4"
              >
                Designs
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              md={4}
              lg={4}
              sx={{
                textAlign: "right",
              }}
            >
              <Button
                variant="outlined"
                startIcon={<AddCircleOutlineIcon />}
                size="large"
              >
                Create Design
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p>To be implemented.</p>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
