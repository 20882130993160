import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
import Table from "../Components/Common/Table";
import Typography from "@mui/material/Typography";
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";

import { AuthContext } from "../store/auth-context";
import Search from "../Components/Common/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import debounce from "lodash.debounce";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { PartnerColumns as columns } from "../Components/Partner/TableHeaders/Columns";

export default function Partners() {
  const authCtx = useContext(AuthContext);

  const [partnerState, setPartnersState] = useState({
    isLoading: false,
    data: [],
    total: 0,
  });

  const initialSearchState = {
    search: "",
    isDirty: false,
  };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const [{ search, isDirty }, setSearchState] = useState(initialSearchState);

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const fetchPartners = useCallback(async () => {
    if (search) {
      setPartnersState((previous) => ({ ...previous, isLoading: true }));
      try {
        const response = await authCtx.request(
          "GET",
          `${process.env.REACT_APP_Prep_Api_Host}partner/search?search=${search}&pageSize=${paginationModel.pageSize}&pageNumber=${paginationModel.page}`,
        );

        if (response && response.data) {
          setPartnersState((old) => ({
            ...old,
            isLoading: false,
            data: response.data.results,
            total: response.data.count,
          }));
        }
      } catch (error) {
        setOpen(true);
        setError(error);
        console.error(error);
      }
    }
  }, [authCtx, paginationModel.page, paginationModel.pageSize, search]);

  useEffect(() => {
    const getPartners = async () => {
      await fetchPartners();
    };
    getPartners();
  }, [fetchPartners]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleSearchChange = (e) => {
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });

    setSearchState((prevState) => ({
      ...prevState,
      search: e.target.value,
      isDirty: true,
    }));
  };

  const handleReset = async () => {
    document.getElementById("input-with-icon-textfield").value = "";
    setSearchState({ ...initialSearchState });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 500);
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs crumbs={[{ title: "Partners" }]}></BreadCrumbs>
          <Grid container spacing={3}>
            <Grid item xs={8} md={8} lg={8}>
              <Typography
                sx={{
                  pt: 0,
                  pb: 3,
                }}
                variant="h4"
              >
                Partners
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              md={4}
              lg={4}
              sx={{
                textAlign: "right",
              }}
            >
              <Button
                variant="outlined"
                startIcon={<AddCircleOutlineIcon />}
                size="large"
              >
                Create Partner
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid container spacing={3} marginBottom={3}>
                <Grid item xs={12} md={5} lg={5}>
                  <Search onChange={debouncedResults} />
                </Grid>
                <Grid textAlign="right" item xs={12} md={7} lg={7}>
                  {isDirty && (
                    <Tooltip title="Reset Search Criteria">
                      <IconButton
                        onClick={handleReset}
                        sx={{
                          p: 2,
                        }}
                        aria-label="delete"
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Refresh Partners List">
                    <IconButton
                      onClick={fetchPartners}
                      sx={{
                        p: 2,
                      }}
                      aria-label="delete"
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              <Table
                data={partnerState.data}
                rowCount={partnerState.total}
                isLoading={partnerState.isLoading}
                columns={columns}
                pagination={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[15, 30, 50, 100]}
                density="standard"
              />
            </Paper>
          </Grid>
        </Grid>
        {error && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
              {`${error.response.status} Error:  ${error.response.data}`}
            </Alert>
          </Snackbar>
        )}
      </Grid>
    </>
  );
}
