import { React, useState, useContext } from "react";
import { AuthContext } from "../../store/auth-context";
import { MessageContext } from "../../store/message-context";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

const UpdateField = ({
  params,
  title,
  dialogTitle,
  isDisabled,
  field,
  fieldParam,
}) => {
  const authCtx = useContext(AuthContext);
  const messageCtx = useContext(MessageContext);
  const [open, setOpen] = useState(false);
  const [updateValue, setUpdateValue] = useState("");

  const update = () => {
    if (updateValue) {
      switch (field) {
        case "Color":
          console.log("color: " + updateValue);
          updateJobItem(Object.assign({}, params.row, { color: updateValue }));
          break;
        case "Size":
          if (isNaN(updateValue)) {
            messageCtx.showMessage("error", `${updateValue} is not a number.`);
            break;
          }
          updateJobItem(
            Object.assign({}, params.row, { maxWidth: parseInt(updateValue) }),
          );
          break;
      }
    }
    setOpen(false);
  };

  const updateJobItem = (jobItem) => {
    authCtx
      .request(
        "PATCH",
        `${process.env.REACT_APP_Prep_Api_Host}jobitem/`,
        jobItem,
      )
      .then((response) => {
        if (response.data === 1) {
          // Update the job status to reflect the change in data.
          switch (field) {
            case "Color":
              params.row.color = updateValue;
              break;
            case "Size":
              params.row.size = updateValue;
              break;
          }
        } else {
          messageCtx.showMessage(
            "error",
            `Error retrieving jobitems: ${response.data}`,
          );
        }
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setTextValue = (event) => {
    setUpdateValue(event.target.value);
  };

  return (
    <span>
      <Button disabled={isDisabled} onClick={(e) => handleClickOpen()}>
        <small>{title}</small>
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <TextField
            onChange={setTextValue}
            autoFocus
            margin="dense"
            id="name"
            label={field}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={update}>Update</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};

export default UpdateField;
