import StatusChip from "../StatusChip";
import { jobStatuses } from "../../../shared/jobStatuses";
import { jobDevices } from "../../../shared/jobDevices";
import Icon from "../Icon";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import Link from "@mui/material/Link";

export const JobColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    renderCell: (params) => (
      <Link
        size="small"
        component={RouterLink}
        to={`/jobItems/${params.row.id}`}
        style={{ marginLeft: 16 }}
      >
        {params.row.id}
      </Link>
    ),
  },
  {
    field: "name",
    headerName: "Job Name",
    width: 250,
    valueGetter: (params) => {
      return `${params.row.name}`;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 180,
    renderCell: (params) => <StatusChip status={params.row.status} />,
  },
  {
    field: "createdDate",
    headerName: "Created Date",
    width: 160,
    valueGetter: (params) => {
      return moment(params.row.createDate).format("MM/DD/YY hh:mm A");
    },
  },
  {
    field: "processedDate",
    headerName: "Processed Date",
    width: 160,
    valueGetter: (params) => {
      return params.row.generatedDate
        ? moment(params.row.generatedDate).format("MM/DD/YY hh:mm A")
        : "Not Processed";
    },
  },
  {
    field: "type",
    headerName: "Type",
    width: 130,
    valueGetter: (params) => {
      return `${params.row.type === 1 ? "Regular" : "Replacements"}`;
    },
  },
  {
    field: "tools",
    headerName: "Tools",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          <Icon
            params={params}
            title="Activate"
            jobIcon="ArrowRightIcon"
            isDisabled={
              params.row.status !== jobStatuses.CREATING_LIST &&
              params.row.status !== jobStatuses.ERROR_GENERATING_FILES &&
              params.status !== jobStatuses.CUT
            }
            action="updateStatus"
            actionParam="1"
          />
          <Icon
            params={params}
            title="Mark Complete"
            jobIcon="TaskAltIcon"
            isDisabled={
              params.row.status !== jobStatuses.READY_TO_CUT &&
              params.row.status !== jobStatuses.CUTTING &&
              params.row.status !== jobStatuses.READY_TO_PRINT &&
              params.row.status !== jobStatuses.PRINTING
            }
            action="updateStatus"
            actionParam="5"
          />
          <Icon
            params={params}
            title="Repoen"
            jobIcon="RestorePageIcon"
            isDisabled={
              params.row.status <= jobStatuses.READY_TO_CUT &&
              params.row.status >= jobStatuses.CUT &&
              params.row.status !== jobStatuses.READY_TO_PRINT
            }
            action="updateStatus"
            actionParam="0"
          />
          <Icon
            params={params}
            title="Delete"
            jobIcon="RotateRight"
            isDisabled={
              params.row.status !== jobStatuses.ERROR_GENERATING_FILES
            }
            action="delete"
            actionParam=""
          />
          <Icon
            params={params}
            title={
              params.row.jobdevice === jobDevices.PRINTER
                ? "Flip to Flatbed"
                : "Flip to Roller"
            }
            jobIcon="FlipIcon"
            isDisabled={
              params.row.jobdevice !== jobDevices.PRINTER &&
              params.row.jobdevice !== jobDevices.PRINTER_FLATBED
            }
            action="flip"
            actionParam={
              params.row.jobdevice === jobDevices.PRINTER
                ? jobDevices.PRINTER_FLATBED
                : jobDevices.PRINTER
            }
          />
        </>
      );
    },
  },
];
