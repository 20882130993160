import * as React from "react";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function PartnerDetail() {
  const params = useParams();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs page="Partners"></BreadCrumbs>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  pt: 0,
                  pb: 3,
                }}
                variant="h4"
              >
                Partner {params.id}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}></Grid>
        </Grid>
      </Grid>
    </>
  );
}
