import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthContextProvider from "./store/auth-context";
import MessageContextProvider from "./store/message-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <Auth0Provider
      domain={process.env.REACT_APP_Auth0_Domain}
      clientId={process.env.REACT_APP_Auth0_ClientId}
      scope="openid profile email read:current_user update:current_user_metadata read:order"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_Auth0_Audience,
      }}
    >
      <AuthContextProvider>
        <MessageContextProvider>
          <App />
        </MessageContextProvider>
      </AuthContextProvider>
    </Auth0Provider>
  </HashRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
