import React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

export default function Address({ address }) {
  if (!address) {
    return (
      <div className="address">
        <Typography variant="body2">
          <p className="name">Address Not Found.</p>
        </Typography>
      </div>
    );
  }
  const { firstName, lastName, address1, address2, city, state, zip, country } =
    address;
  return (
    <div className="address">
      <Typography variant="body2">
        <p className="name">
          {firstName} {lastName}
        </p>
        <p>{address1}</p>
        {address2 && <p>{address2}</p>}
        <p>
          {city}, {state}
        </p>
        <p>{zip}</p>
        <p>{country}</p>
      </Typography>
    </div>
  );
}

Address.propTypes = {
  address: PropTypes.object,
};
