import React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

export default function Label({ variant, label, style }) {
  return (
    <Typography style={style} variant={variant}>
      {label}
    </Typography>
  );
}

Label.propTypes = {
  variant: PropTypes.string.isRequired,
};
