import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Outlet, Link as RouterLink } from "react-router-dom";
import MainAppBar from "../Components/Common/AppBar/AppBar";

import Search, {
  SearchIconWrapper,
  StyledInputBase,
} from "../Components/Common/AppBar/Search";
import Drawer from "../Components/Common/Drawer";
import MainListItems from "../Components/Common/ListItems";
import SubListItems from "../Components/Common/SubListItems";
import { colors } from "../shared/colors";
import { AuthContext } from "../store/auth-context";
import { MessageContext } from "../store/message-context";
import SignIn from "./SignIn";
const Main = ({ drawerWidth }) => {
  const authCtx = useContext(AuthContext);
  const messageCtx = useContext(MessageContext);
  const user = authCtx.userDetails;
  const isAuthenticated = authCtx.isUserAuthenticated;
  const [open, setOpen] = useState(true);
  const [theme, setTheme] = useState("light");
  const [anchorElUser, setAnchorElUser] = useState(null);

  useEffect(() => {
    if (user && !user["admin/prepUserId"]) {
      messageCtx.showMessage(
        "error",
        `User doesn't have a valid MemberID attached to their Auth0 account.`
      );
    }
  }, [user]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const lightMode = {
    primary: {
      main: colors.blueTheme,
    },
    secondary: {
      main: colors.orangeTheme,
    },
  };

  const darkMode = {
    primary: {
      main: colors.orangeTheme,
    },
    secondary: {
      main: colors.blueTheme,
    },
  };

  const Theme =
    theme === "light"
      ? createTheme({
          palette: {
            mode: theme,
            primary: lightMode.primary,
            secondary: lightMode.secondary,
          },
        })
      : createTheme({
          palette: {
            mode: theme,
            primary: darkMode.primary,
            secondary: darkMode.secondary,
          },
        });

  function handleThemeChange() {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }

  const handleLogout = () => authCtx.logoutUser();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  if (isAuthenticated) {
    return (
      <ThemeProvider theme={Theme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <MainAppBar position="absolute" open={open} width={drawerWidth}>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                Prep Sportswear Admin
              </Typography>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user.name} src={user.picture} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem component={RouterLink} to="/settings">
                    Settings
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </MainAppBar>
          <Drawer variant="permanent" open={open} width={drawerWidth}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              <MainListItems />
              <Divider sx={{ my: 1 }} />
              <SubListItems />
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Outlet />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    );
  } else {
    return <SignIn />;
  }
};

Main.propTypes = {
  drawerWidth: PropTypes.number,
};

export default Main;
