import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { MuiColorInput } from "mui-color-input";
import React from "react";

import BreadCrumbs from "../Components/Common/Breadcrumbs";

export default function ImageTester() {
  const crumbs = [
    {
      title: "Images",
    },
    {
      title: "Testing",
    },
  ];

  const stageBaseUrl = "https://images.staging.teams.prepsportswear.com";
  const prodBaseUrl = "https://images.teams.prepsportswear.com";

  const [imageParams, setImageParams] = React.useState({
    baseUrl: stageBaseUrl,
    productId: 5074,
    productColor: "royal",
    productSide: "front",
    xtplPath: "2ink/generic/9",
    color1: "f8f8f8",
    color2: "aaaaaa",
    parameter1: "Seattle",
    parameter2: "2020",
    parameter3: "Prep",
    parameter4: "",
    parameter5: "",
  });

  const handleBaseUrlChange = function (e) {
    setImageParams({
      ...imageParams,
      baseUrl: e.target.value,
    });
  };

  const handleProductIdChange = function (e) {
    setImageParams({
      ...imageParams,
      productId: e.target.value,
    });
  };

  const handleProductColorChange = function (e) {
    setImageParams({
      ...imageParams,
      productColor: e.target.value,
    });
  };

  const handleProductSideChange = function (e) {
    setImageParams({
      ...imageParams,
      productSide: e.target.value,
    });
  };

  const handleXtplPathChange = function (e) {
    setImageParams({
      ...imageParams,
      xtplPath: e.target.value,
    });
  };

  const handleColor1Change = function (e) {
    setImageParams({
      ...imageParams,
      color1: e.replace("#", "").toLowerCase(),
    });
  };

  const handleColor2Change = function (e) {
    setImageParams({
      ...imageParams,
      color2: e.replace("#", "").toLowerCase(),
    });
  };

  const handleParameter1Change = function (e) {
    setImageParams({
      ...imageParams,
      parameter1: e.target.value,
    });
  };

  const handleParameter2Change = function (e) {
    setImageParams({
      ...imageParams,
      parameter2: e.target.value,
    });
  };

  const handleParameter3Change = function (e) {
    setImageParams({
      ...imageParams,
      parameter3: e.target.value,
    });
  };
  const handleParameter4Change = function (e) {
    setImageParams({
      ...imageParams,
      parameter4: e.target.value,
    });
  };
  const handleParameter5Change = function (e) {
    setImageParams({
      ...imageParams,
      parameter5: e.target.value,
    });
  };

  const handleEnvironmentChange = function (e) {
    setImageParams({
      ...imageParams,
      baseUrl: e.target.value === "prod" ? prodBaseUrl : stageBaseUrl,
    });
  };

  const imageUrl = `${imageParams.baseUrl}/pi?p=${imageParams.productId}/${
    imageParams.productColor
  }_${imageParams.productSide}&d=${imageParams.xtplPath
    .replace(/\//g, "%2F")
    .toLowerCase()}&param=${imageParams.parameter1}%7C${
    imageParams.parameter2
  }%7C${imageParams.parameter3}%7C${imageParams.parameter4}%7C${
    imageParams.parameter5
  }%7C&color=${imageParams.color1}ff%7C${
    imageParams.color2
  }ff&h=624&output=png`;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs crumbs={crumbs}>
            <Typography color="text.primary">Breadcrumbs</Typography>
          </BreadCrumbs>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  pt: 0,
                  pb: 3,
                }}
                variant="h4"
              >
                Image Testing
              </Typography>
              <Paper
                sx={{
                  p: 3,
                  mb: 3,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={5} lg={5}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          placeholder="https://images.staging.teams.prepsportswear.com"
                          fullWidth
                          required
                          onChange={handleBaseUrlChange}
                          id="storeName"
                          value={imageParams.baseUrl}
                          label="Base URL"
                          helperText="Base or domain portion of the url. Determines stage vs. production"
                        />
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={
                            imageParams.baseUrl === prodBaseUrl
                              ? "prod"
                              : "stage"
                          }
                          onChange={handleEnvironmentChange}
                        >
                          <FormControlLabel
                            value="prod"
                            control={<Radio size="small" />}
                            label="Production"
                          />
                          <FormControlLabel
                            value="stage"
                            control={<Radio size="small" />}
                            label="Staging"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          placeholder="5575"
                          fullWidth
                          required
                          id="productId"
                          value={imageParams.productId}
                          onChange={handleProductIdChange}
                          label="Product ID"
                          helperText="Product's ID to show the design on"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          placeholder="black_heather"
                          fullWidth
                          required
                          value={imageParams.productColor}
                          onChange={handleProductColorChange}
                          id="productColor"
                          label="Product Color"
                          helperText="Product color to show the design on"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            Side
                          </FormLabel>
                          <RadioGroup
                            row
                            value={imageParams.productSide}
                            onChange={handleProductSideChange}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="front"
                              name="radio-side"
                              control={<Radio />}
                              label="Front"
                            />
                            <FormControlLabel
                              value="back"
                              name="radio-side"
                              control={<Radio />}
                              label="Back"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          placeholder="2ink/generic/1"
                          fullWidth
                          required
                          value={imageParams.xtplPath}
                          onChange={handleXtplPathChange}
                          id="xtplPath"
                          label="XTPL Path"
                          helperText="Path to the design XTPL in the Google Cloud Bucket"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <MuiColorInput
                          format="hex"
                          onChange={_.debounce(handleColor1Change, 300)}
                          value={`#${imageParams.color1}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <MuiColorInput
                          format="hex"
                          onChange={_.debounce(handleColor2Change, 300)}
                          value={`#${imageParams.color2}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          placeholder="ABBEVILLE"
                          fullWidth
                          required
                          value={imageParams.parameter1}
                          onChange={handleParameter1Change}
                          id="param1"
                          label="Parameter 1"
                          helperText="Often the name for print. Ex. Seattle"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          placeholder="2000"
                          fullWidth
                          required
                          value={imageParams.parameter2}
                          onChange={handleParameter2Change}
                          id="param2"
                          label="Parameter 2"
                          helperText="Often a year, or player number"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          placeholder="YELLOW JACKETS"
                          fullWidth
                          required
                          value={imageParams.parameter3}
                          onChange={handleParameter3Change}
                          id="param3"
                          label="Parameter 3"
                          helperText="Often the teams mascot"
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          placeholder=""
                          fullWidth
                          required
                          value={imageParams.parameter4}
                          onChange={handleParameter4Change}
                          id="param4"
                          label="Parameter 4"
                          helperText=""
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <TextField
                          placeholder=""
                          fullWidth
                          required
                          value={imageParams.parameter5}
                          onChange={handleParameter5Change}
                          id="param5"
                          label="Parameter 5"
                          helperText=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={7}
                    lg={7}
                    sx={{
                      textAlign: "center",
                      mt: 10,
                    }}
                  >
                    <img src={imageUrl} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
