import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import LoginButton from "../Components/Auth0/LoginButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../Components/Common/CopyRight";

const defaultTheme = createTheme();

export default function SignIn() {
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ maxWidth: 345, mt: 5, mb: 1.5, p: 2 }}>
                <CardContent>
                  <Typography component="h1" variant="h5">
                    Prep Sportswear Sign in
                  </Typography>
                </CardContent>
                <CardActions>
                  <LoginButton />
                </CardActions>
              </Card>
            </Grid>
          </Grid>

          <Copyright />
        </Container>
      </ThemeProvider>
    </>
  );
}
