import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../store/auth-context";
import Table from "../../Components/Common/Table";
import { OrderHistoryColumns as columns } from "../Order/TableHeaders/Columns";

export default function OrderHistory() {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const [history, setHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function getHistory() {
      setIsLoading(true);

      try {
        const historyData = await authCtx.request(
          "GET",
          `${process.env.REACT_APP_Prep_Api_Host}order/${params.id}/history`,
        );

        if (historyData && historyData.data) {
          setHistory(historyData.data);
          setIsLoading(false);
          setShowHistory(true);
        }
      } catch (error) {
        setOpen(true);
        setError(error);
      }
    }
    getHistory();
  }, [authCtx, params.id]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} md={6} lg={12}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          History
        </Typography>
      </Grid>
      {showHistory && (
        <Grid xs={12} md={12} lg={12}>
          <Table
            data={history}
            rowCount={history.length}
            isLoading={isLoading}
            columns={columns}
            disablePaging={true}
            pagination={[]}
            pageSizeOptions={[]}
            density="standard"
            columnVisibilityModel={{
              id: false,
            }}
          />
        </Grid>
      )}
      {error && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseAlert}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
