import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import React, { useCallback, useContext } from "react";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
import { AuthContext } from "../store/auth-context";

export default function SiteSettings() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(false);
  const [alertType, setAlertType] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setIsLoading(false);
  };
  const authCtx = useContext(AuthContext);
  const crumbs = [
    {
      title: "Settings",
    },
  ];

  const setSbAlert = (message, type, show) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertOpen(show);
  };

  const handleSbAlertClose = () => {
    setAlertOpen(false);
  };

  const flushRedis = useCallback(async () => {
    setIsLoading(true);

    await Promise.all([
      authCtx.request(
        "DELETE",
        `${process.env.REACT_APP_Prep_Api_Host}redis/flush`
      ),
      authCtx.request(
        "DELETE",
        `${process.env.REACT_APP_Prep_Api_Host}designs/cache/flush`
      ),
    ])
      .then(function (arrayOfValuesOrErrors) {
        handleDialogClose();
        setSbAlert("Successfully cleared caches", "success", true);
      })
      .catch(function (err) {
        setIsLoading(false);
        setSbAlert(`${err.code}: ${err.message}`, "error", true);
      });
  }, [authCtx]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs crumbs={crumbs}>
            <Typography color="text.primary">Breadcrumbs</Typography>
          </BreadCrumbs>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  pt: 0,
                  pb: 3,
                }}
                variant="h4"
              >
                Settings
              </Typography>
              <Paper
                sx={{
                  p: 3,
                  mb: 3,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5" gutterBottom>
                      Operations
                    </Typography>
                  </Grid>
                  {authCtx.isInRole(["Admin"]) && (
                    <>
                      <Grid
                        item
                        xs={9}
                        md={9}
                        lg={9}
                        sx={{ p: 2, borderBottom: 1, borderColor: "grey.300" }}
                      >
                        <Typography variant="subtitle1" gutterBottom>
                          <strong>Flush Site Caches</strong>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Clear the Redis cache used by the Prep Sportswear
                          front-end and the in-memory design caches in
                          PrepWeb/Admin.
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        md={3}
                        lg={3}
                        sx={{
                          p: 2,
                          borderBottom: 1,
                          borderColor: "grey.300",
                          textAlign: "right",
                        }}
                      >
                        <Box display="flex" justifyContent="flex-end">
                          <Button onClick={handleDialogOpen} variant="outlined">
                            Flush Caches
                          </Button>
                        </Box>
                      </Grid>

                      {process.env.NODE_ENV !== "prod" && (
                        <>
                          <Grid item xs={9} md={9} lg={9} sx={{ p: 2 }}>
                            <Typography variant="subtitle1" gutterBottom>
                              <strong>
                                Back Date Plan Production Jobs (PPJ)
                              </strong>
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              Set previous date in for PPJ to pick up orders.
                              Often used for testing. Only available in stage.
                            </Typography>
                          </Grid>
                          <Grid item xs={3} md={3} lg={3} sx={{ p: 2 }}>
                            <Box display="flex" justifyContent="flex-end">
                              <Button variant="outlined" disabled>
                                Backdate PPJ
                              </Button>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Flush Caches</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              You are about to flush the site's caches. This can have negative
              performance repercussions for several minutes as the caches are
              rebuilt and should be done sparingly.
            </p>
            <p>Are you sure you want to continue?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            p: 3,
          }}
        >
          <Button onClick={handleDialogClose}>Cancel</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="warning"
            onClick={flushRedis}
            loadingPosition="start"
            startIcon={<ClearIcon />}
          >
            Flush the Caches
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={alertOpen}
        onClose={handleSbAlertClose}
        severity={alertType}
        autoHideDuration={6000}
      >
        <Alert severity={alertType}>{alertMessage}</Alert>
      </Snackbar>
    </>
  );
}
