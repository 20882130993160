import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
import Table from "../Components/Common/Table";
import Typography from "@mui/material/Typography";
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";

import moment from "moment";
import { AuthContext } from "../store/auth-context";
import Search from "../Components/Common/Search";
import DateRange from "../Components/Order/DateRange";
import RefreshIcon from "@mui/icons-material/Refresh";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import debounce from "lodash.debounce";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { OrderColumns as columns } from "../Components/Order/TableHeaders/Columns";

export default function Orders() {
  const authCtx = useContext(AuthContext);
  const defaultDateRange = [moment().subtract(60, "d"), moment()];

  const [ordersState, setOrdersState] = useState({
    isLoading: false,
    data: [],
    total: 0,
  });

  const initialSearchState = {
    search: "",
    dates: defaultDateRange,
    isDirty: false,
  };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const [{ search, dates, isDirty }, setSearchState] =
    useState(initialSearchState);

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const fetchOrders = useCallback(async () => {
    setOrdersState((previous) => ({ ...previous, isLoading: true }));
    const formattedDates = [dates[0].format(), dates[1].format()];
    try {
      const response = await authCtx.request(
        "GET",
        `${process.env.REACT_APP_Prep_Api_Host}Order?search=${search}&dateRange=${formattedDates}&pageSize=${paginationModel.pageSize}&pageNumber=${paginationModel.page}`,
      );

      if (response && response.data) {
        setOrdersState((old) => ({
          ...old,
          isLoading: false,
          data: response.data.results,
          total: response.data.count,
        }));
      }
    } catch (error) {
      setOpen(true);
      setError(error);
      console.error(error);
    }
  }, [authCtx, dates, paginationModel.page, paginationModel.pageSize, search]);

  useEffect(() => {
    const getOrders = async () => {
      await fetchOrders();
    };
    getOrders();
  }, [fetchOrders]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handleDateRangeChange = (newValue) => {
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });
    const splitDates = newValue.toString().split(",");

    setSearchState((prevState) => ({
      ...prevState,
      dates: [moment(splitDates[0]), moment(splitDates[1])],
      isDirty: true,
    }));
  };

  const handleSearchChange = (e) => {
    setPaginationModel({
      page: 0,
      pageSize: paginationModel.pageSize,
    });

    setSearchState((prevState) => ({
      ...prevState,
      search: e.target.value,
      isDirty: true,
    }));
  };

  const handleReset = async () => {
    document.getElementById("input-with-icon-textfield").value = "";
    setSearchState({ ...initialSearchState });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 500);
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs crumbs={[{ title: "Orders" }]}></BreadCrumbs>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  pt: 0,
                  pb: 3,
                }}
                variant="h4"
              >
                Orders
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid container spacing={2} marginBottom={3}>
                <Grid item xs={12} md={3} lg={2}>
                  <Search onChange={debouncedResults} />
                </Grid>
                <Grid item xs={12} md={6} lg={9}>
                  <DateRange
                    dates={dates}
                    onChange={handleDateRangeChange}
                    defaultDateRange={defaultDateRange}
                  />
                </Grid>

                <Grid textAlign="right" item xs={4} md={3} lg={1}>
                  {isDirty && (
                    <Tooltip title="Reset Search Criteria">
                      <IconButton
                        onClick={handleReset}
                        sx={{
                          p: 2,
                        }}
                        aria-label="delete"
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Refresh Orders List">
                    <IconButton
                      onClick={fetchOrders}
                      sx={{
                        p: 2,
                      }}
                      aria-label="delete"
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Table
                data={ordersState.data}
                rowCount={ordersState.total}
                isLoading={ordersState.isLoading}
                columns={columns}
                pagination={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[15, 30, 50, 100]}
                density="standard"
              />
            </Paper>
          </Grid>
        </Grid>
        {error && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              sx={{ width: "100%" }}
            >
              {`${error.response.status} Error:  ${error.response.data}`}
            </Alert>
          </Snackbar>
        )}
      </Grid>
    </>
  );
}
