import { jobStatuses } from "../../../shared/jobStatuses";
import UpdateField from "../UpdateField";
import Icon from "../Icon";

export const JobItemColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    valueGetter: (params) => {
      return `${params.row.id}`;
    },
  },
  {
    field: "fileGroup",
    headerName: "File Group",
    width: 100,
    valueGetter: (params) => {
      return `${params.row.fileGroup}`;
    },
  },
  {
    field: "color",
    headerName: "Color",
    width: 200,
    renderCell: (params) => {
      return (
        <>
          {params.row.color}
          <small>
            <UpdateField
              params={params}
              title="change"
              dialogTitle="Change Color"
              field="Color"
            />
          </small>
        </>
      );
    },
  },
  {
    field: "label",
    headerName: "Label",
    width: 200,
    valueGetter: (params) => {
      return `${params.row.label}`;
    },
  },
  {
    field: "size",
    headerName: "Size",
    width: 160,
    renderCell: (params) => {
      return (
        <>
          {params.row.maxWidth}w
          <small>
            <UpdateField
              params={params}
              title="change"
              dialogTitle="Change Size"
              field="Size"
            />
          </small>
        </>
      );
    },
  },
  {
    field: "vinylPart",
    headerName: "Vinyl Part",
    width: 80,
    valueGetter: (params) => {
      return `${params.row.vinylPart}`;
    },
  },
  {
    field: "packageFileName",
    headerName: "Package File Name",
    width: 200,
    valueGetter: (params) => {
      return `${params.row.packageFileName}`;
    },
  },
  {
    field: "materialLength",
    headerName: "Material Length",
    width: 130,
    valueGetter: (params) => {
      return `${params.row.materialLength}`;
    },
  },
  {
    field: "lastScan",
    headerName: "Last Scanned",
    width: 120,
    valueGetter: (params) => {
      return `${params.row.lastScan}`;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    valueGetter: (params) => {
      return `${params.row.status}`;
    },
  },
  {
    field: "tools",
    headerName: "Tools",
    width: 100,
    renderCell: (params) => {
      return (
        <>
          <Icon
            params={params}
            title="Request Repl."
            jobIcon="ArrowRightIcon"
            isDisabled={
              params.row.jobStatus !== jobStatuses.ERROR_GENERATING_FILES
            }
            action="requestCutRepl"
          />
          <Icon
            params={params}
            title="Delete"
            jobIcon="RotateRight"
            isDisabled={
              params.row.jobStatus !== jobStatuses.ERROR_GENERATING_FILES ||
              params.row.jobStatus !== jobStatuses.CREATING_LIST
            }
            action="deleteCut"
          />
        </>
      );
    },
  },
];
