import Chip from "@mui/material/Chip";
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import LoopIcon from "@mui/icons-material/Loop";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import MergeIcon from "@mui/icons-material/Merge";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function StatusChip(status) {
  let statusDetail = {
    text: "",
    color: "",
  };

  switch (status.status) {
    case -8:
      statusDetail = {
        text: "Error Printing",
        color: "error",
        icon: <ErrorOutlineIcon />,
      };
      break;
    case -4:
      statusDetail = {
        text: "Error Cutting",
        color: "error",
        icon: <ErrorOutlineIcon />,
      };
      break;
    case -3:
      statusDetail = {
        text: "Ready to Cut - Error Queing Files",
        color: "error",
        icon: <ErrorOutlineIcon />,
      };
      break;
    case -2:
      statusDetail = {
        text: "Error Generating Files",
        color: "error",
        icon: <ErrorOutlineIcon />,
      };
      break;
    case -1:
      statusDetail = {
        text: "Error Creating",
        color: "error",
        icon: <ErrorOutlineIcon />,
      };
      break;
    case 0:
      statusDetail = { text: "Creating List", color: "success" };
      break;
    case 1:
      statusDetail = {
        text: "Ready To Generate Files",
        variant: "outlined",
        variant: "outlined",
        icon: <QueryBuilderIcon />,
      };
      break;
    case 2:
      statusDetail = {
        text: "Generating Files",
        variant: "outlined",
        color: "primary",
        icon: <LocalPrintshopIcon />,
      };
      break;
    case 3:
      statusDetail = {
        text: "Ready To Cut",
        color: "success",
        variant: "outlined",
        icon: <CheckCircleOutlineIcon />,
      };
      break;
    case 4:
      statusDetail = {
        text: "Cutting",
        color: "secondary",
        variant: "outlined",
        icon: <ContentCutIcon />,
      };
      break;
    case 5:
      statusDetail = {
        text: "Cut",
        color: "success",
        icon: <CheckCircleOutlineIcon />,
      };
      break;
    case 6:
      statusDetail = { text: "Delete and Regen Files", color: "success" };
      break;
    case 7:
      statusDetail = {
        text: "Ready to Print",
        color: "success",
        variant: "outlined",
        icon: <CheckCircleOutlineIcon />,
      };
      break;
    case 8:
      statusDetail = {
        text: "Printing",
        color: "secondary",
        variant: "outlined",
        icon: <LoopIcon />,
      };
      break;
    case 9:
      statusDetail = {
        text: "Ready to Merge",
        color: "success",
        icon: <CheckCircleOutlineIcon />,
      };
      break;
    case 10:
      statusDetail = {
        text: "Merging",
        color: "secondary",
        variant: "outlined",
        icon: <MergeIcon />,
      };
      break;
    default:
      statusDetail = { text: "Unknown", color: "primary" };
      break;
  }

  return (
    <Chip
      sx={statusDetail.sx ? statusDetail.sx : ""}
      icon={statusDetail.icon}
      label={statusDetail.text}
      size="small"
      color={statusDetail.color}
      variant={statusDetail.variant ? statusDetail.variant : ""}
    ></Chip>
  );
}
