import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

export const ProductColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    renderCell: (params) => (
      <Link
        size="small"
        component={RouterLink}
        to={`/product/${params.row.id}`}
        style={{ marginLeft: 16 }}
      >
        {params.row.id}
      </Link>
    ),
  },
  {
    field: "sku",
    headerName: "SKU",
    width: 80,
    valueGetter: (params) => {
      return params.row.sku;
    },
  },
  {
    field: "name",
    headerName: "Name",
    width: 450,
    valueGetter: (params) => {
      return params.row.name;
    },
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    valueGetter: (params) => {
      return params.row.category;
    },
  },
];
