import AddCardIcon from "@mui/icons-material/AddCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import BlockIcon from "@mui/icons-material/Block";
import DiscountIcon from "@mui/icons-material/Discount";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { parsePhoneNumber } from "awesome-phonenumber";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Address from "../Components/Common/Address";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
import Label from "../Components/Common/Label";
import LoadingOverlay from "../Components/Common/LoadingOverlay";
import SpeedDialer from "../Components/Common/SpeedDialer";
import AddPaymentForm from "../Components/Order/Edit/AddPaymentForm";
import PaymentDetails from "../Components/Order/Edit/Details";
import IssuePromoCodeForm from "../Components/Order/Edit/IssuePromoCodeForm";
import IssueRefundForm from "../Components/Order/Edit/IssueRefundForm";
import ReplacementForm from "../Components/Order/Edit/ReplacementForm";
import VoidOrderForm from "../Components/Order/Edit/VoidOrderForm";
import OrderEmail from "../Components/Order/Email";
import OrderHistory from "../Components/Order/History";
import LineItems from "../Components/Order/LineItems";
import OrderNotes from "../Components/Order/Notes";
import { AuthContext } from "../store/auth-context";

const styles = {
  summaryDivider: {
    borderTop: "1px solid rgb(227 227 227)",
  },
  textRight: {
    textAlign: "right",
  },
  summaryLabel: {
    fontWeight: "bold",
  },
  orderid: {
    lineHeight: 1,
  },
  orderdate: {
    color: "#868686",
    fontSize: ".9rem",
  },
  sticky: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  },
};

export default function Orders() {
  const authCtx = useContext(AuthContext);
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const [phone, setPhone] = useState("");
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [openVoidModal, setOpenVoidModal] = useState(false);
  const [openIssuePromoCodeModal, setIssuePromoCodeModal] = useState(false);
  const [openReplacementModal, setOpenReplacementModal] = useState(false);

  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [open, setOpen] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    async function getOrder() {
      setIsLoading(true);

      // If there are no search params, there is no need to search
      const orderData = await authCtx.request(
        "GET",
        `${process.env.REACT_APP_Prep_Api_Host}order/${params.id}`
      );

      if (orderData) {
        setOrder(orderData.data);
        setIsLoading(false);
        const phn = orderData.data.billingAddress.phoneNumbers[0].number
          ? orderData.data.billingAddress.phoneNumbers[0].number
          : "orderData.data.billingAddress.phoneNumbers[0].number";
        setPhone(parsePhoneNumber(phn, { regionCode: "US" }));
      }
    }
    getOrder();
  }, [authCtx, params.id]);

  const crumbs = [
    {
      title: "Orders",
      route: "/orders",
    },
    {
      title: params.id,
    },
  ];

  // #region Add Payment
  const addPayment = () => {
    setOpenAddPaymentModal(true);
  };

  const handleCloseAddPaymentModal = () => setOpenAddPaymentModal(false);

  // #endregion

  const addFreeProduct = () => {
    console.log("Adding Free Product");
  };

  // #region Replacement
  const addReplacement = () => {
    setOpenReplacementModal(true);
  };

  const handleCloseReplacementModal = (e) => {
    setMessage(e.message);
    setSeverity(e.severity);
    setOpen(true);
    return setOpenReplacementModal(false);
  };

  // #region Issue Promo Code

  const issuePromoCode = () => {
    setIssuePromoCodeModal(true);
  };

  const handleCloseIssuePromoCodeModal = () => setIssuePromoCodeModal(false);
  // #endregion

  // #region Void Order
  const voidOrder = () => {
    setOpenVoidModal(true);
  };

  const handleCloseVoidOrderModal = () => setOpenVoidModal(false);

  // #endregion

  // #region Refund Order
  const refundOrder = () => {
    setOpenRefundModal(true);
  };

  const handleCloseRefundModal = () => setOpenRefundModal(false);

  // #endregion

  const actions = [
    { icon: <AddCardIcon />, name: "Add Payment", onClick: addPayment },
    {
      icon: <ShoppingBagIcon />,
      name: "Add Free Product",
      onClick: addFreeProduct,
    },
    { icon: <AutoModeIcon />, name: "Replacement", onClick: addReplacement },
    {
      icon: <DiscountIcon />,
      name: "Issue Promo Code",
      onClick: issuePromoCode,
    },
    { icon: <BlockIcon />, name: "Void", onClick: voidOrder },
    { icon: <AttachMoneyIcon />, name: "Refund", onClick: refundOrder },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs crumbs={crumbs}>
            {" "}
            <Typography color="text.primary">Breadcrumbs</Typography>
          </BreadCrumbs>
          {isLoading && <LoadingOverlay />}
          <Grid container spacing={3}>
            {order && (
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    fontWeight="fontWeightMedium"
                    variant="h4"
                    style={styles.orderid}
                  >
                    Order: {params.id}
                  </Typography>
                  <div style={styles.orderdate}>
                    {moment(order.createDate).fromNow()}
                    {"   |   "}
                    {moment(order.createDate).format("MMMM Do YYYY, h:mm:ss a")}
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 3,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <LineItems />
                    <Grid
                      container
                      spacing={0}
                      sx={{ mt: 4 }}
                      style={styles.summaryDivider}
                    >
                      <Grid item xs={6} md={6} lg={9} mt={3}></Grid>
                      <Grid item xs={6} md={6} lg={3} mt={3}>
                        <Grid container style={styles.textRight}>
                          <Grid xs={6} md={6} lg={6}>
                            <Label
                              variant="body2"
                              style={styles.summaryLabel}
                              label="SubTotal:"
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={6}>
                            <Label
                              variant="body2"
                              label={`$${order.billingAmounts.subTotal}`}
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={6}>
                            <Label
                              variant="body2"
                              style={styles.summaryLabel}
                              label="Shipping:"
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={6} style={styles.textRight}>
                            <Label
                              variant="body2"
                              label={`$${order.billingAmounts.shipping}`}
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={6}>
                            <Label
                              variant="body2"
                              style={styles.summaryLabel}
                              label="Tax:"
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={6} style={styles.textRight}>
                            <Label
                              variant="body2"
                              label={`$${order.billingAmounts.tax}`}
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={6}>
                            <Label
                              variant="body2"
                              style={styles.summaryLabel}
                              label="Discounts"
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={6} style={styles.textRight}>
                            <Label
                              variant="body2"
                              label={`$${order.billingAmounts.discount}`}
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={6}>
                            <Label
                              variant="body2"
                              style={styles.summaryLabel}
                              label="Total"
                            />
                          </Grid>
                          <Grid xs={6} md={6} lg={6} style={styles.textRight}>
                            <Label
                              variant="body2"
                              label={`$${order.billingAmounts.total}`}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 3,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant="h5">Billing Address</Typography>

                        <Address address={order.billingAddress} />
                        <Typography variant="body2">
                          <p>{phone.number.national}</p>
                          <p>
                            {order.billingAddress.emailAddresses[0].address}
                          </p>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant="h5">Shipping Address</Typography>
                        <Address address={order.shippingAddress} />
                      </Grid>

                      <Grid item xs={12} md={4} lg={4}>
                        <Typography variant="h5">Shipping Details</Typography>
                        <Typography variant="body2">
                          <p>Shipping Method: U.S. Postal Service Priority</p>
                          <p>Tracking Number: Not yet available.</p>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <PaymentDetails />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 3,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <OrderNotes />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 3,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <OrderHistory />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <OrderEmail />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Modal open={openAddPaymentModal} onClose={handleCloseAddPaymentModal}>
        <Box sx={styles.modal}>
          <AddPaymentForm onClose={handleCloseAddPaymentModal} />
        </Box>
      </Modal>
      <Modal open={openRefundModal} onClose={handleCloseRefundModal}>
        <Box sx={styles.modal}>
          <IssueRefundForm onClose={handleCloseRefundModal} />
        </Box>
      </Modal>
      <Modal open={openVoidModal} onClose={handleCloseVoidOrderModal}>
        <Box sx={styles.modal}>
          <VoidOrderForm onClose={handleCloseVoidOrderModal} />
        </Box>
      </Modal>
      <Modal
        open={openIssuePromoCodeModal}
        onClose={handleCloseIssuePromoCodeModal}
      >
        <Box sx={styles.modal}>
          <IssuePromoCodeForm onClose={handleCloseIssuePromoCodeModal} />
        </Box>
      </Modal>

      <Modal
        fullWidth
        open={openReplacementModal}
        onClose={handleCloseReplacementModal}
      >
        <Box sx={styles.modal}>
          <ReplacementForm onClose={(e) => handleCloseReplacementModal(e)} />
        </Box>
      </Modal>

      <div style={styles.sticky}>
        <SpeedDialer direction="up" actions={actions} />
      </div>
      {message && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
