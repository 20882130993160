import React, { createContext, useState } from "react";

export const MessageContext = createContext({
  showMessage: (open, messageSeverity, alertMessage) => {},
  hideMessage: () => {},
});

function MessageContextProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [messageSeverity, setMessageSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");

  function showMessage(messageSeverity, alertMessage) {
    setMessageSeverity(messageSeverity);
    setAlertMessage(alertMessage);
    setOpen(true);
  }

  function hideMessage() {
    setOpen(false);
  }

  return (
    <MessageContext.Provider
      value={{
        open,
        messageSeverity,
        alertMessage,
        showMessage,
        hideMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
}

export default MessageContextProvider;
