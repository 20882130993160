import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../store/auth-context";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Menu } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import { REDIRECTOR_URL } from "../../shared/constants";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { colors } from "../../shared/colors";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { OrderItemContext } from "../../store/order-item-context";
import { FormatProductName } from "../../shared/utils";

const printTechniques = [
  "Applique",
  "DTF",
  "DTG",
  "DTGEpson",
  "DyeSub",
  "Embroidery",
  "Printer",
  "Screen",
  "Sticker",
  "Undefined",
  "Vinyl",
  "WallArt",
];

const styles = {
  menuItem: {
    margin: 0,
    marginRight: 5,
    textDecoration: "none",
    color: colors.orangeTheme,
  },
};

export default function LineItems() {
  const authCtx = useContext(AuthContext);
  const { Items, setItems } = useContext(OrderItemContext);

  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visitSchool, setVisitSchool] = useState("");
  const [visitProduct, setVisitProduct] = useState("");
  const [visitSchoolAdmin, setVisitSchoolAdmin] = useState("");
  const [visitProductAdmin, setVisitProductAdmin] = useState("");
  const [visitDesignFrontAdmin, setVisitDesignFrontAdmin] = useState("");
  const [visitDesignBackAdmin, setVisitDesignBackAdmin] = useState("");
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const params = useParams();

  useEffect(() => {
    const timeOutId = setTimeout(() => getOrderItems(), 0);
    return () => clearTimeout(timeOutId);

    async function getOrderItems() {
      setIsLoading(true);

      // If there are no search params, there is no need to search
      const orderItems = await authCtx.request(
        "GET",
        `${process.env.REACT_APP_Prep_Api_Host}order/${params.id}/items/products`,
      );
      if (orderItems) {
        setItems(orderItems.data);

        setIsLoading(false);
      }
    }
  }, [authCtx, params.id]);

  const handleMoreClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    const { storeId, productId, designs } = item;
    const frontDesignID = designs[0].id;
    const backDesignID = designs[1].id;
    setVisitSchool(`${REDIRECTOR_URL}?schoolId=${storeId}`);
    setVisitProduct(
      `${REDIRECTOR_URL}?schoolId=${storeId}&productId=${productId}`,
    );

    setVisitSchoolAdmin(`/store/${storeId}`);
    setVisitProductAdmin(`/product/${productId}`);
    setVisitDesignFrontAdmin(`/design/${frontDesignID}`);
    setVisitDesignBackAdmin(`/design/${backDesignID}`);
  };

  const handleItemClicked = (e, item) => {
    const { id, orderId } = item;
    navigate(`/order/${orderId}/${id}`);
  };

  const getImageParameters = (params) => {
    if (params && params.length > 0) {
      const removedNullEmpty = _.remove(params, (p) => {
        if (p) {
          return p;
        }
      });
      return removedNullEmpty.join("|");
    }
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      <Grid container spacing={3}>
        {Items && (
          <>
            {isLoading && (
              <Box sx={{ width: 300 }}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            )}

            {Items.map((item, index) => (
              <Grid key={index} item xs={12} md={12} lg={6}>
                <Card>
                  <CardHeader
                    avatar={
                      <Chip
                        icon={<LocalPrintshopOutlinedIcon />}
                        label={printTechniques[item.printTechnique]}
                        variant="outlined"
                      />
                    }
                    action={
                      <IconButton
                        aria-label="settings"
                        onClick={(e) => handleMoreClick(e, item)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    }
                  />
                  <div
                    style={{
                      position: "relative",
                      textAlign: "center",
                    }}
                  >
                    {item.designs.map((design, index) => {
                      const area = design.area
                        .replace(" ", "")
                        .replace("Full", "");
                      const params = getImageParameters(design.parameter);
                      return (
                        <>
                          {area !== "Back" && (
                            <img
                              style={{
                                width: 200,
                              }}
                              alt="Product Back"
                              src={`${process.env.REACT_APP_Prep_ImageServer_Host}/pi?p=${item.productId}%2F${item.color}_${area}&d=mascot%2F2ink%2F1%2Fcougars&param=${params}&color=1B1640ff%7Cf8f8f8ff&h=480&output=png`}
                            />
                          )}
                          {area === "Back" && (
                            <img
                              style={{
                                height: 100,
                                bottom: 20,
                                right: 20,
                                position: "absolute",
                              }}
                              alt="Product Front"
                              src={`${process.env.REACT_APP_Prep_ImageServer_Host}/pi?p=${item.productId}%2F${item.color}_${area}&d=mascot%2F2ink%2F1%2Fcougars&param=${params}&color=1B1640ff%7Cf8f8f8ff&h=480&output=png`}
                            />
                          )}
                        </>
                      );
                    })}
                  </div>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {FormatProductName(item.product.name)}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Grid item xs={8} md={8} lg={8}>
                      <Typography variant="body2" color="text.secondary">
                        <Link>{item.sku}</Link> / {item.color} / {item.size}
                      </Typography>
                    </Grid>
                    <Grid textAlign="right" item xs={4} md={4} lg={4}>
                      <Typography variant="body2" color="text.secondary">
                        {item.quantity} x ${item.billingAmounts.total}
                      </Typography>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </>
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>
            <Link target="_blank" href={visitSchool} style={styles.menuItem}>
              Visit School{" "}
            </Link>
            <OpenInNewIcon sx={{ color: `${colors.orangeTheme}` }} />
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link target="_blank" href={visitProduct} style={styles.menuItem}>
              Visit Product{" "}
            </Link>
            <OpenInNewIcon sx={{ color: `${colors.orangeTheme}` }} />
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <RouterLink
              target="_blank"
              to={visitSchoolAdmin}
              style={styles.menuItem}
            >
              School (Admin)
            </RouterLink>
            <OpenInNewIcon sx={{ color: `${colors.orangeTheme}` }} />
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <RouterLink
              target="_blank"
              to={visitProductAdmin}
              style={styles.menuItem}
            >
              Product (Admin)
            </RouterLink>
            <OpenInNewIcon sx={{ color: `${colors.orangeTheme}` }} />
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <RouterLink
              target="_blank"
              to={visitDesignFrontAdmin}
              style={styles.menuItem}
            >
              Design Front(Admin)
            </RouterLink>
            <OpenInNewIcon sx={{ color: `${colors.orangeTheme}` }} />
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <RouterLink
              target="_blank"
              to={visitDesignBackAdmin}
              style={styles.menuItem}
            >
              Design Back (Admin)
            </RouterLink>
            <OpenInNewIcon sx={{ color: `${colors.orangeTheme}` }} />
          </MenuItem>
        </Menu>
      </Grid>
    </>
  );
}
