import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Search as SearchIcon } from "@mui/icons-material";
const Search = ({ onChange }) => {
  return (
    <Grid item xs={12} md={12} lg={12}>
      <TextField
        inputRef={(input) => input && input.focus()}
        id="input-with-icon-textfield"
        label="Search"
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
    </Grid>
  );
};

export default Search;
