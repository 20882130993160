import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import React from "react";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
export default function OrderLineItemDetail() {
  const params = useParams();
  const crumbs = [
    {
      title: "Orders",
      route: "/orders",
    },
    {
      title: params.id,
      route: `/order/${params.id}`,
    },
    {
      title: params.itemId,
    },
  ];
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <BreadCrumbs crumbs={crumbs}> </BreadCrumbs>
      </Grid>
      <p>Not implemented have at it...</p>
    </Grid>
  );
}
