import Link from "@mui/material/Link";
import { parsePhoneNumber } from "awesome-phonenumber";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { USDollar } from "../../../shared/utils";
import Icon from "../Icon";
import StatusChip from "../StatusChip";

export const DashboardOrderColumns = [
  {
    field: "id",
    headerName: "Order ID",
    renderCell: (params) => (
      <Link
        size="small"
        component={RouterLink}
        to={`/order/${params.row.id}`}
        style={{ marginLeft: 16 }}
      >
        {params.row.id}
      </Link>
    ),
    width: 85,
  },
  {
    field: "createdDate",
    headerName: "Order Date",
    width: 130,
    valueGetter: (params) => {
      return moment(params.row.createDate).format("MM/DD hh:mm A");
    },
  },
  {
    field: "customerName",
    headerName: "Name",
    width: 160,
    valueGetter: (params) => {
      return `${params.row.billingAddress.firstName} ${params.row.billingAddress.lastName}`;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    renderCell: (params) => <StatusChip status={params.row.status} />,
  },
  {
    field: "orderTotal",
    headerName: "Total",
    width: 80,
    align: "right",
    valueGetter: (params) => {
      return USDollar.format(params.row.billingAmounts.total);
    },
  },
];

export const OrderColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    renderCell: (params) => (
      <Link
        size="small"
        component={RouterLink}
        to={`/order/${params.row.id}`}
        style={{ marginLeft: 16 }}
      >
        {params.row.id}
      </Link>
    ),
  },
  {
    field: "createdDate",
    headerName: "Date",
    width: 160,
    valueGetter: (params) => {
      return moment(params.row.createDate).format("MM/DD/YY hh:mm A");
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    renderCell: (params) => <StatusChip status={params.row.status} />,
  },
  {
    field: "customerName",
    headerName: "Name",
    width: 220,
    valueGetter: (params) => {
      return `${params.row.billingAddress.firstName} ${params.row.billingAddress.lastName}`;
    },
  },
  {
    field: "email",
    headerName: "Email",
    valueGetter: (params) => {
      const email = params.row.billingAddress.emailAddresses[0].address;
      return email ? email : "";
    },
    width: 260,
  },
  {
    field: "phone",
    headerName: "Phone",
    valueGetter: (params) => {
      const pn = parsePhoneNumber(
        params.row.billingAddress.phoneNumbers[0].number,
        { regionCode: "US" }
      );
      return pn.number ? pn.number.national : "";
    },
    width: 140,
  },
  {
    field: "orderTotal",
    headerName: "Total",
    width: 100,
    align: "right",
    valueGetter: (params) => {
      return USDollar.format(params.row.billingAmounts.total);
    },
  },
];

export const OrderHistoryColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    valueGetter: (params) => {
      return `${params.row.id}`;
    },
  },
  {
    field: "createdDate",
    headerName: "Order Date",
    width: 130,
    valueGetter: (params) => {
      return moment(params.row.createDate).format("MM/DD hh:mm A");
    },
  },
  {
    field: "member",
    headerName: "Member",
    width: 130,
    valueGetter: (params) => {
      return `${params.row.memberName}`;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    renderCell: (params) => <StatusChip status={params.row.status} />,
  },
  {
    field: "customerText",
    headerName: "Customer Text",
    width: 350,
    valueGetter: (params) => {
      return `${params.row.customerText}`;
    },
  },
  {
    field: "comments",
    headerName: "Comments",
    width: 300,
    valueGetter: (params) => {
      return `${params.row.comments}`;
    },
  },
];

export const OrderNotesColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    valueGetter: (params) => {
      return `${params.row.id}`;
    },
  },
  {
    field: "createDate",
    headerName: "Date",
    width: 130,
    valueGetter: (params) => {
      return moment(params.row.createDate).format("MM/DD hh:mm A");
    },
  },
  {
    field: "note",
    headerName: "Note",
    width: 450,
    valueGetter: (params) => {
      return `${params.row.note}`;
    },
  },
  {
    field: "productionNote",
    headerName: "Note for Production",
    width: 450,
    valueGetter: (params) => {
      return `${params.row.productionNote}`;
    },
  },
];

export const EmailColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    valueGetter: (params) => {
      return `${params.row.id}`;
    },
  },
  {
    field: "dateSent",
    headerName: "Date Sent",
    width: 130,
    valueGetter: (params) => {
      return moment(params.row.dateSent).format("MM/DD hh:mm A");
    },
  },
  {
    field: "to",
    headerName: "To",
    width: 350,
    valueGetter: (params) => {
      return `${params.row.to}`;
    },
  },
  {
    field: "subject",
    headerName: "Subject",
    width: 820,
    valueGetter: (params) => {
      return `${params.row.subject}`;
    },
  },
  {
    field: "resend",
    align: "right",
    headerName: "Resend",
    width: 75,
    renderCell: (params) => {
      return (
        <>
          <Icon
            params={params}
            title="Resend"
            jobIcon="RotateRight"
            action="resendEmail"
          />
        </>
      );
    },
  },
];
