import { useContext } from "react";
import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InventoryIcon from "@mui/icons-material/Inventory";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import { Link as RouterLink } from "react-router-dom";
import StorefrontIcon from "@mui/icons-material/Storefront";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import Tooltip from "@mui/material/Tooltip";
import { AuthContext } from "../../store/auth-context";

export default function MainListItems() {
  const authCtx = useContext(AuthContext);

  return (
    <>
      <ListItemButton component={RouterLink} to="/">
        <Tooltip title="Dashboard">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      {authCtx.isInRole(["Customer Service"]) && (
        <>
          <ListItemButton component={RouterLink} to="/orders">
            <Tooltip title="Orders">
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Orders" />
          </ListItemButton>
        </>
      )}
      {authCtx.isInRole("StoreReader") && (
        <>
          <ListItemButton component={RouterLink} to="/stores">
            <Tooltip title="Stores">
              <ListItemIcon>
                <StorefrontIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Stores" />
          </ListItemButton>
        </>
      )}
      {authCtx.isInRole("ProductReader") && (
        <>
          <ListItemButton component={RouterLink} to="/products">
            <Tooltip title="Products">
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Products" />
          </ListItemButton>
        </>
      )}
      {authCtx.isInRole("DesignReader") && (
        <>
          <ListItemButton component={RouterLink} to="/designs">
            <Tooltip title="Designs">
              <ListItemIcon>
                <DesignServicesIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Designs" />
          </ListItemButton>
        </>
      )}
      {authCtx.isInRole("PartnerReader") && (
        <>
          <ListItemButton component={RouterLink} to="/partners">
            <Tooltip title="Partners">
              <ListItemIcon>
                <HandshakeIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Partners" />
          </ListItemButton>
        </>
      )}
      {authCtx.isInRole(["Manufacturing"]) && (
        <>
          <ListItemButton component={RouterLink} to="/jobs">
            <Tooltip title="Jobs">
              <ListItemIcon>
                <AlarmOnIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Jobs" />
          </ListItemButton>
        </>
      )}
    </>
  );
}
