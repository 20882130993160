import React, { useContext } from "react";
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import { AuthContext } from "../../store/auth-context";

const LoginButton = () => {
  const authCtx = useContext(AuthContext);

  const handleLogin = () => {
    authCtx.loginUser();
  };
  return (
    <Button
      fullWidth
      size="large"
      startIcon={<GoogleIcon />}
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      onClick={handleLogin}
    >
      Log In
    </Button>
  );
};

export default LoginButton;
