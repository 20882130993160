import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const LoadingOverlay = () => {
  return (
    <Box m={2} sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  );
};

export default LoadingOverlay;
