import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { AuthContext } from "../../../store/auth-context";
import { useParams } from "react-router-dom";
import LoadingOverlay from "../../Common/LoadingOverlay";
import Label from "../../Common/Label";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Link from "@mui/material/Link";
import { STRIPE_PAYMENT_URL } from "../../../shared/constants";

const styles = {
  link: {
    margin: 0,
    marginRight: 5,
    display: "inline",
    marginTop: -10,
  },
  label: {
    margin: 5,
  },
  labelWithButton: {
    marginTop: -9,
    marginRight: 5,
    marginLeft: 5,
  },
};
export default function PaymentDetails() {
  const [payment, setPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    async function getPayment() {
      setIsLoading(true);

      const paymentData = await authCtx.request(
        "GET",
        `${process.env.REACT_APP_Prep_Api_Host}payment/${params.id}`,
      );

      if (paymentData) {
        setPayment(paymentData.data);
        setIsLoading(false);
      }
    }
    getPayment();
  }, [authCtx, params.id]);
  return (
    <Paper
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={12}>
          <Typography variant="h5">Payment</Typography>
          {isLoading && <LoadingOverlay />}

          {payment && (
            <Grid container mt={2}>
              <Grid>
                <Grid xs={12} md={12} mr={4}>
                  <Label style={styles.label} variant="body2" label="ID" />
                </Grid>
                <Grid xs={12} md={12} mr={4}>
                  <Label style={styles.label} variant="body2" label="Created" />
                </Grid>
                <Grid xs={12} md={12} mr={4}>
                  <Label style={styles.label} variant="body2" label="Amount" />
                </Grid>
                <Grid xs={12} md={12} mr={4}>
                  <Label
                    style={styles.label}
                    variant="body2"
                    label="Stripe Token ID"
                  />
                </Grid>
                <Grid xs={12} md={12} mr={4}>
                  <Label
                    style={styles.label}
                    variant="body2"
                    label="IP Address"
                  />
                </Grid>
                <Grid xs={12} md={12} mr={4}>
                  <Label
                    style={styles.label}
                    variant="body2"
                    label="Description"
                  />
                </Grid>
              </Grid>
              <Grid>
                <Grid xs={12} md={12}>
                  <Label
                    style={styles.label}
                    variant="body2"
                    label={payment.id}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <Label
                    style={styles.label}
                    variant="body2"
                    label={moment(payment.createdDate).format(
                      "MMMM Do YYYY, h:mm:ss a",
                    )}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <Label
                    variant="body2"
                    style={styles.label}
                    label={`$${payment.amount}`}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12}>
                  <Label
                    variant="body2"
                    style={styles.labelWithButton}
                    label={
                      <Link
                        target="_blank"
                        href={`${STRIPE_PAYMENT_URL}/${payment.transactionID}`}
                      >
                        <span style={styles.link}>{payment.transactionID}</span>
                        <OpenInNewIcon />
                      </Link>
                    }
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <Label
                    variant="body2"
                    style={styles.label}
                    label={payment.ipAddress}
                  />
                </Grid>
                <Grid xs={12} md={12}>
                  <Label
                    variant="body2"
                    style={styles.label}
                    label={payment.description}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
