import * as React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import SnackbarAlert from "./Components/Common/SnackbarAlert";
import CreateStore from "./Pages/CreateStore";
import Dashboard from "./Pages/Dashboard";
import DesignDetail from "./Pages/DesignDetail";
import Designs from "./Pages/Designs";
import ImageTester from "./Pages/ImageTester";
import JobItem from "./Pages/JobItems";
import Job from "./Pages/Jobs";
import OrderDetail from "./Pages/OrderDetail";
import OrderLineItemDetail from "./Pages/OrderLineItemDetail";
import Order from "./Pages/Orders";
import PartnerDetail from "./Pages/PartnerDetail";
import Partners from "./Pages/Partners";
import ProductDetail from "./Pages/ProductDetail";
import Products from "./Pages/Products";
import SignIn from "./Pages/SignIn";
import StoreDetail from "./Pages/StoreDetail";
import Stores from "./Pages/Stores";
import OrderItemContextProvider from "./store/order-item-context";

import Main from "./Pages/Main";
import SiteSettings from "./Pages/SiteSettings";
function App() {
  return (
    <div>
      <Routes>
        <Route element={<Main drawerWidth={240} />}>
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/login" exact element={<SignIn />} />
          <Route
            path="/order/:id"
            element={
              <OrderItemContextProvider>
                <OrderDetail />
              </OrderItemContextProvider>
            }
          />
          <Route path="/image/testing" element={<ImageTester />} />
          <Route path="/order/:id/:itemId" element={<OrderLineItemDetail />} />
          <Route path="/orders" element={<Order />} />
          <Route path="/jobs" element={<Job />} />
          <Route path="/jobItems/:id" element={<JobItem />} />
          <Route path="/stores" element={<Stores />} />
          <Route path="/store/:id" element={<StoreDetail />} />
          <Route path="/stores/create" element={<CreateStore />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/:id" element={<ProductDetail />} />
          <Route path="/designs" element={<Designs />} />
          <Route path="/design/:id" element={<DesignDetail />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/partner/:id" element={<PartnerDetail />} />
          <Route path="/settings" element={<SiteSettings />} />
        </Route>
      </Routes>
      <SnackbarAlert />
    </div>
  );
}

export default App;
