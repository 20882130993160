import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

export default function Table({
  columns,
  data,
  density,
  isLoading,
  disablePaging,
  onPaginationModelChange,
  pageSizeOptions,
  pagination,
  rowCount,
  columnVisibilityModel,
}) {
  return (
    <div data-testid="data-table">
      <Grid item xs={12} md={12} lg={12}>
        <DataGrid
          autoHeight
          columns={columns}
          loading={isLoading}
          hideFooter={disablePaging}
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={pageSizeOptions}
          paginationMode="server"
          paginationModel={pagination}
          rowCount={rowCount}
          rows={data}
          density={density}
          columnVisibilityModel={columnVisibilityModel}
        />
      </Grid>
    </div>
  );
}
Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  density: PropTypes.string,
  isLoading: PropTypes.bool,
  onPaginationModelChange: PropTypes.func,
  pageSizeOptions: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  rowCount: PropTypes.number,
  columnVisibilityModel: PropTypes.object,
};
Table.defaultProps = {
  disablePaging: false,
};
