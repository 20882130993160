import { AuthContext } from "../../store/auth-context";
import { MessageContext } from "../../store/message-context";
import { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { RotateRight } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

const Icon = ({ params, title, jobIcon, isDisabled, action, actionParam }) => {
  const authCtx = useContext(AuthContext);
  const messageCtx = useContext(MessageContext);

  const getIconFromName = (iconName) => {
    switch (iconName) {
      case "RotateRight":
        return <RotateRight />;
      default:
        return <ArrowRightIcon />;
    }
  };
  const icon = getIconFromName(jobIcon);

  const doAction = (actionName, actionParameter) => {
    switch (actionName) {
      case "resendEmail":
        resendEmail();
        break;
      default:
        break;
    }
  };

  const resendEmail = () => {
    authCtx
      .request(
        "POST",
        `${process.env.REACT_APP_Prep_Api_Host}email/resend?emailId=${params.row.id}`,
      )
      .then((response) => {
        if (response.data.success) {
          messageCtx.showMessage("info", `Email resent.`);
        } else {
          messageCtx.showMessage(
            "error",
            `Error resending email: ${response.data.statusCode}`,
          );
        }
      });
  };

  return (
    <span>
      <IconButton
        aria-label={title}
        disabled={isDisabled}
        onClick={(e) => doAction(`${action}`, `${actionParam}`)}
      >
        <Tooltip title={title}>{icon}</Tooltip>
      </IconButton>
    </span>
  );
};

export default Icon;
