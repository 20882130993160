export const REDIRECTOR_URL = `https://www.prepsportswear.com/redirector.aspx`;
export const STRIPE_PAYMENT_URL = "https://dashboard.stripe.com/payments";
export const ADD_PAYMENT_REASONS = [
  "Customer Shipping Upgrade",
  "CS Shipping Upgrade",
  "Shipping Intercept",
  "Product Change",
  "Design Change",
  "Bulk Order",
  "CS Sizing Replacement",
  "Other",
];
export const PAYMENT_TYPES = ["Credit Card", "Purchase Order"];
export const REFUND_TYPES = [
  "Full",
  "Partial",
  "Shipping",
  "Refund 10%",
  "Refund 15%",
  "Refund 20%",
  "Refund 25%",
  "Refund 30%",
  "Refund 35%",
];

export const REPLACEMENT_REASONS = [
  "Damaged Product",
  "Defective Design",
  "Lost Shipment",
  "Color Issue",
  "Wrong Product/Size Sent",
  "Management Escalation",
  "Order Adjustment",
  "Site/Admin Error",
  "Lost in Production",
  "CS Sizing Remedy",
];

export const REPLACEMENT_COLORS = [
  "Athletic Heather",
  "Black Heather",
  "Charcoal Heather",
  "Green Heather",
  "Indigo Heather",
  "Mustard Heather",
  "Purple Heather",
  "Red Heather",
  "Royal Heather",
  "White",
];

export const REPLACEMENT_SIZES = [
  "Medium - 685",
  "Large - 730",
  "Extra Large - 512",
  "2 Extra Large - 499",
  "3 Extra Large - 217",
];
