import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

export const PartnerColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 75,
    renderCell: (params) => (
      <Link
        size="small"
        component={RouterLink}
        to={`/partner/${params.row.id}`}
        style={{ marginLeft: 16 }}
      >
        {params.row.id}
      </Link>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
  },
  {
    field: "name",
    headerName: "Name",
    width: 280,
  },
  {
    field: "identifier",
    headerName: "Identifier",
    width: 280,
  },
  {
    field: "category",
    headerName: "Category",
    width: 280,
  },
];
