import { createContext, useState } from "react";

export const OrderItemContext = createContext({
  Items: [],
  setItems: (items) => {},
});

function OrderItemContextProvider({ children }) {
  const [Items, setOrderItems] = useState();

  function setItems(items) {
    setOrderItems(items);
  }
  const value = {
    Items,
    setItems,
  };
  return (
    <OrderItemContext.Provider value={value}>
      {children}
    </OrderItemContext.Provider>
  );
}

export default OrderItemContextProvider;
