import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import AddressEdit from "../Components/Common/AddressEdit";
import BreadCrumbs from "../Components/Common/Breadcrumbs";
import ColorPicker from "../Components/Common/ColorPicker";
import StoreTypesDropdown from "../Components/Common/StoreTypesDropDown";
import { AuthContext } from "../store/auth-context";

const styles = {
  updated: {
    color: "rgb(170 170 170)",
  },
  border: {
    border: "1px solid #bda98d",
  },
};

export default function CreateStore() {
  const params = useParams();
  const authCtx = useContext(AuthContext);

  const [store, setStore] = React.useState(null);
  const [storeName, setstoreName] = React.useState("");
  const [shortAbbr, setshortAbbr] = React.useState("");
  const [longAbbr, setlongAbbr] = React.useState("");
  const [mascot, setMascot] = React.useState("");
  const [storeType, setStoreType] = React.useState("");

  const handleStoreChange = function (e) {
    setstoreName(e.target.value);

    // create suggested abbreviations
    var matches = storeName.match(/\b(\w)/g);
    var acronym;
    if (matches) {
      acronym = matches.join("").toUpperCase();
      setlongAbbr(acronym.slice(0, 3));
      setshortAbbr(acronym.slice(0, 2));
    } else {
      setshortAbbr("");
      setlongAbbr("");
    }
  };

  useEffect(() => {
    async function getStore() {
      // If there are no search params, there is no need to search
      const storeData = await authCtx.request(
        "GET",
        `${process.env.REACT_APP_Prep_Api_Host}store/${params.id}`
      );

      if (storeData) {
        setStore(storeData.data);
      }
    }
    getStore();
  }, [authCtx, params.id]);

  const crumbs = [
    {
      title: "Stores",
      route: "/stores",
    },
    {
      title: "Edit",
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumbs crumbs={crumbs}>
            <Typography color="text.primary">Breadcrumbs</Typography>
          </BreadCrumbs>

          <Grid container spacing={3}>
            {store && (
              <Grid item xs={12} md={12} lg={12}>
                {!store.settings.enabled && (
                  <Alert
                    severity="warning"
                    style={styles.border}
                    sx={{
                      mb: 4,
                    }}
                  >
                    <AlertTitle>Warning</AlertTitle>
                    This store is disabled and is not accessible to customers.
                  </Alert>
                )}
                <Typography
                  sx={{
                    pt: 0,
                    pb: 0,
                  }}
                  variant="h4"
                  display="block"
                  gutterBottom
                >
                  {store.configuration.name}
                </Typography>

                <Paper
                  sx={{
                    p: 3,
                    mb: 3,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="h5" gutterBottom>
                        Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        placeholder="Store name"
                        fullWidth
                        required
                        id="storeName"
                        label="Store name"
                        onKeyDown={handleStoreChange}
                        onChange={handleStoreChange}
                        value={store.configuration.name}
                        helperText="Store name used when navigating the site"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        placeholder="Print Name"
                        fullWidth
                        required
                        id="printName"
                        label="Print Name"
                        value={store.configuration.nameForPrint}
                        helperText="Store name used on product embelishments"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        placeholder="Horned Frogs"
                        fullWidth
                        required
                        value={store.configuration.mascot}
                        id="mascot"
                        label="Mascot"
                        helperText="Store mascot. Used in many designs."
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <StoreTypesDropdown value={store.type} />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}></Grid>
                  </Grid>
                </Paper>
                <Paper
                  sx={{
                    p: 3,
                    mb: 3,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <AddressEdit address={store.storeAddress}></AddressEdit>
                </Paper>
                <Paper
                  sx={{
                    p: 3,
                    mb: 3,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="h5" gutterBottom>
                        Configuration
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} md={6} lg={6}>
                          <Typography sx={{ pt: 1.5 }} variant="subtitle1">
                            Color 1
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <ColorPicker
                            value={store.configuration.color1}
                          ></ColorPicker>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <Typography sx={{ pt: 1.5 }} variant="subtitle1">
                            Color 2
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <ColorPicker
                            value={store.configuration.color2}
                          ></ColorPicker>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <Typography sx={{ pt: 1.5 }} variant="subtitle1">
                            Color 3
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <ColorPicker
                            value={store.configuration.color3}
                          ></ColorPicker>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1} md={1} lg={1}></Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} md={6} lg={6}>
                          <Typography sx={{ pt: 1.5 }} variant="subtitle1">
                            Short Abbreviation
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <TextField
                            fullWidth
                            required
                            value={store.configuration.shortAbbreviation}
                            id="outlined-required"
                            placeholder="JH"
                          />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <Typography sx={{ pt: 1.5 }} variant="subtitle1">
                            Long Abbreviation
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                          <TextField
                            fullWidth
                            required
                            value={store.configuration.longAbbreviation}
                            id="outlined-required"
                            placeholder="JHS"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper
                  sx={{
                    p: 3,
                    mb: 3,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="h5" gutterBottom>
                        Settings
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      md={9}
                      lg={9}
                      sx={{ p: 2, borderBottom: 1, borderColor: "grey.300" }}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        <strong>Enabled</strong>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        The store is active on the site allowing customers to
                        purchase products from this store.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      lg={3}
                      sx={{
                        p: 2,
                        borderBottom: 1,
                        borderColor: "grey.300",
                        textAlign: "right",
                      }}
                    >
                      <Box display="flex" justifyContent="flex-end">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked
                                checked={store.settings.enabled}
                              />
                            }
                          />
                        </FormGroup>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      md={9}
                      lg={9}
                      sx={{ p: 2, borderBottom: 1, borderColor: "grey.300" }}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        <strong>Show in Site Search</strong>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Allow the store to be found using the site's own search
                        functionality.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      md={3}
                      lg={3}
                      sx={{ p: 2, borderBottom: 1, borderColor: "grey.300" }}
                    >
                      <Box display="flex" justifyContent="flex-end">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked
                                checked={!store.settings.hideFromSearch}
                              />
                            }
                          />
                        </FormGroup>
                      </Box>
                    </Grid>
                    <Grid item xs={9} md={9} lg={9} sx={{ p: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        <strong>Search Engine Crawling</strong>
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Allow search engines to crawl and index this store.
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3} lg={3} sx={{ p: 2 }}>
                      <Box display="flex" justifyContent="flex-end">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch checked={!store.settings.doNotIndex} />
                            }
                          />
                        </FormGroup>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid item xs={12} md={12} lg={12}>
                  <Stack direction="row" spacing={2}>
                    <Button size="large" variant="contained">
                      Update Store
                    </Button>
                    <Button size="large" variant="text" href="#/stores">
                      Cancel
                    </Button>
                  </Stack>
                  <Typography
                    style={styles.updated}
                    sx={{
                      pt: 3,
                      pb: 3,
                    }}
                    variant="caption"
                    display="block"
                    gutterBottom
                  >
                    Last Updated:{" "}
                    {moment(store.updatedDate).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                    <br />
                    Created:{" "}
                    {moment(store.createDate).format("MMMM Do YYYY, h:mm:ss a")}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
